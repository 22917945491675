import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface segmentProps {
    value?: any;
    onSelect: (val: string) => void

}

const Segment: React.FC<segmentProps> = ({ value = 'cleaners', onSelect }) => {
    return (
        <Flex bg={'white'} borderRadius={'6px'} p={'6px'}  >
            <SegmentButton onSelect={onSelect} currentValue={value} label={'Cleaners'} value={'cleaners'} />
            <SegmentButton onSelect={onSelect} currentValue={value} label={'Properties'} value={'properties'} />
        </Flex>
    )
}


const SegmentButton: React.FC<any> = ({ label, value, currentValue, onSelect }) => {

    let isActive = currentValue === value;

    const activeStyle = {
        bg: '#00ACAF',
        color: 'white'
    }
    return (
        <Flex color={'#7D7D7D'} fontWeight={500} borderRadius={'5px'} alignItems={'center'} justifyContent={'center'} py={2} flex={1} sx={isActive ? activeStyle : {}} cursor={'pointer'} onClick={() => onSelect(value)}>
            <Text>{label}</Text>
        </Flex>
    )
}





export default Segment;
