import { addDoc, collection } from "firebase/firestore"
import { db } from "./init"


export const sentInviteMail = async (email: string, data: any) => {
  const ref = collection(db, 'mails');



  await addDoc(ref, {
    to: email,
    message: {
      subject: "You got invitation from Bnbchecklist!",
      html: `
          You have been invited on bnbchecklist. Please Signup
          <a href='${window.location.href}/signup?invitationId=${data.invitationId}'>signup</a>
          `

    }
  })

}