import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import Routes from "./components/Routes/Routes";
import "react-datepicker/dist/react-datepicker.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './index.css'
import { ContextComponent } from "./context/authContext";

export const App: React.FC = () => (
  <ChakraProvider theme={theme}>
    <ContextComponent>
      <Routes />
    </ContextComponent>
  </ChakraProvider>
)
