

import { Box, Input, Text } from '@chakra-ui/react';
import { useRef, useState } from 'react';

const Inputs: React.FC<any> = ({ placeholder, type, primaryStyles, change, onChange, sx, value, isError, labelClassname, ...otherProps }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef();

  const inputStyles = {
    fontWeight: 'medium',
    fontSize: '16px',
    height: '100%',
    minWidth: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '8px',
    padding: '22px 0 0 17px',
    pl: '13px',
/*     background: "white",
 */    ...sx,
    ...primaryStyles,
  };

  const labelStyles = {
    color: '#6b6b6b',
    fontSize: isFocused ? '14px' : '15px',
    fontWeight: '400',
    position: 'relative',
    top: (!!value || isFocused) ? '-55px' : '-42px',

    bottom: '10px',
    left: '15px',
    transition: '0.2s ease all',
  };

  const blurChange = (e: any) => {
    if (e.target.value === '') {
      setIsFocused(false);
      if (change) {
        change(false);
      }
    }
  };

  const focusChange = () => {
    setIsFocused(true);
  };

  const changeHandler = ({ target }: any) => {
    /*  if (target.value.includes('@')) {
       change(true);
     } */

    onChange(target)
  };

  return (
    /* @ts-ignore */
    <Box height='61px' width='100%' onClick={() => inputRef.current?.focus()}>
      <Input

        className='design-input'
        type={type ? type : 'text'}
        ref={inputRef}
        sx={{
          ...inputStyles,/*  ...(isError && {

            background: '#fef8f6',
            border: '1px solid #c13515 '
          }) */
        }}
        onFocus={focusChange}
        onBlur={blurChange}
        onChange={changeHandler}
        value={value}
        {...otherProps}
      />
      <Text className={labelClassname && labelClassname} sx={{
        ...labelStyles,
      }} as='span'>
        {placeholder}
      </Text>
    </Box>
  );
};

export default Inputs;