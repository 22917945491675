import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { collection, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../components/UI/Header';
import { Context } from '../../context/authContext';
import { db } from '../../firebase/init';
import NotificationItem from './NotificationItem';

import { IoMdNotificationsOutline } from 'react-icons/io';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Notifications: React.FC = () => {
    const [notifications, setNotifications] = useState<null | any[]>(null);
    const [isLoading, setIsLoading] = useState(true)
    const { currentUser } = useContext<any>(Context)
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) return

        const ref = collection(db, 'notifications',)
        onSnapshot(ref, (snapshot) => {
            let notifications: any = []
            snapshot.docs.forEach((el) => {
                notifications.push(el.data())
            })
            notifications = notifications.filter((el: any) => el.notifyTo.uid === currentUser.uid);
            setNotifications(notifications.sort((a: any, b: any) => b.createdAt.seconds - a.createdAt.seconds))

            setIsLoading(false)
        })

    }, [currentUser]);

    return (
        <Box px={'30px'}>
            <Header />
            <Box>
                <Button pl={0} bg={'none'} color={'gray.500'} onClick={() => navigate(`/property`)}>
                    <AiOutlineArrowLeft />
                    <Text ml={2}>
                        Go Home
                    </Text>
                </Button>
            </Box>
            <Flex alignItems={'center'}>

                <Text fontWeight={600} fontSize={'22px'} mr={1}>All notifications  </Text>
                <IoMdNotificationsOutline style={{
                    fontSize: '25px',
                    fontWeight: 600
                }} />
            </Flex>
            {
                isLoading ? (
                    <Spinner />
                ) : (

                    <Box mt={3}>
                        {
                            /* @ts-ignore */
                            notifications?.length > 0 ? notifications?.map((el) => <NotificationItem  {...el} />) : (
                                <Text>No Notifications</Text>
                            )
                        }
                    </Box>
                )
            }
        </Box>
    )
}


export default Notifications;