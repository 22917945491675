import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import Inputs from "../../components/UI/Inputs";

type StepInputProps = {
    handleAction: (val: string) => void;
    label: string;
}

const StepInput: React.FC<StepInputProps> = ({ handleAction, label }) => {
    const [text, setText] = useState('')
    return (
        <Box mt={'20px'} /* p={'10px'} */ borderRadius="16px">
            <Flex alignItems={'center'}>
                <Box borderRadius={'8px'} bg={'white'} flex={1}>
                    <Inputs

                        isError={false}
                        onChange={(e: any) => {
                            setText(e.value)
                        }}
                        value={text}
                        placeholder='Step name'
                        primaryStyles={{}}
                    />

                </Box>
                <Flex alignSelf={'stretch'} ml={4} justifyContent={'flex-end'}>
                    <Button bg={'white'} px={5} height={'100%'} onClick={() => handleAction(text)}>
                        <AiOutlinePlus style={{
                            fontSize: '18px',
                            fontWeight: 600
                        }} />
                    </Button>
                </Flex>

            </Flex>
        </Box>
    )
}

export default StepInput;