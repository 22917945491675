import { Box, Input, Text } from '@chakra-ui/react'
import React, { InputHTMLAttributes } from 'react';


interface CustomInputProps extends React.ComponentProps<"input"> {
    children: React.ReactNode;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: any
}

const CustomInput: React.FC<CustomInputProps> = ({ children, value, handleChange, ...otherProps }) => {
    return (
        <Box fontWeight={500} mb={'11px'}>
            {children}
            {/* @ts-ignore */}
            <Input
                paddingLeft={"11px"}
                _placeholder={{
                    fontSize: '14px'
                }}
                value={value}
                onChange={handleChange}
                height={'44px'}
                mt={'6px'}
                outline={'1px solid #A2A7AD'}
                placeholder='Enter your email'
                {...otherProps}
            />
        </Box>
    )
}

export const InputLabel: React.FC<{ label: string }> = ({ label }) => {

    return (
        <Text textTransform={'capitalize'} fontSize="15px" mb="4px" fontWeight={500}>
            {label}
        </Text>
    )

}


export default CustomInput;