import { Spinner } from '@chakra-ui/react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

// @ts-ignore
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isUserAvailable, setIsUserAvailable] = useState<any>(undefined)
    const navigate = useNavigate()
    useEffect(() => {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            setIsUserAvailable(user)
        })
    }, [])

    if (isUserAvailable === undefined) {
        return (
            <Spinner />
        )
    }

    if (isUserAvailable === null) {
        // Navigate
        return navigate('/login')
    }


    return children




}

export default ProtectedRoute;