import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "./init";


export const getAllCleanings = async () => {






}


export const updateCleaning = async (id: string, obj: {}) => {
    try {
        const ref = doc(db, 'cleanings', id);
        await updateDoc(ref, {
            ...obj
        })

    } catch (error) {
        console.log(error)
    }

}


export const getAllCleaners = async () => {
    const ref = collection(db, 'users');
    const cleanersQuery = query(ref, where('accountType', '==', 'cleaner'));
    const snapshot = await getDocs(cleanersQuery);
    let data: any = [];
    snapshot.forEach((el) => {
        data.push(el.data())
    })
    return data
}


export const getTeamAllCleaners = async (teamId: string) => {
    const ref = doc(db, 'teams', teamId);
    const snapshot = await getDoc(ref);
    const teamData: any = snapshot.data();
    const cleaners: any = []
    teamData.members.forEach((el: any) => {
        if (el.roles.includes('Cleaner')) {
            cleaners.push(el)
        }
    })

    return cleaners

}

