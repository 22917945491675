import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

type CountsType = {
    label: string;
    value: string;
    setValue: (val: string) => void;
    threshold: string;
}

const Counts: React.FC<CountsType> = ({ label, value, threshold = 0.5, setValue }) => {

    const handleIncrease = () => {
        setValue(String(Number(value) + Number(threshold)))
    }

    const handleDecrease = () => {
        if (Number(value) < 1) {
            return
        }

        setValue(String(Number(value) - Number(threshold)))
    }
    return (
        <Flex my={5} alignItems={'center'} justifyContent={'space-between'}>
            <Text fontWeight={500} fontSize={'15px'} >{label}</Text>
            <Flex sx={counterIconContainerStyle} alignItems={'center'}>
                <AiOutlineMinus onClick={handleDecrease} style={{
                    cursor: 'pointer',
                    fontSize: '12px'
                }} />
                <Text mx={4}>
                    {value}
                </Text>
                <AiOutlinePlus onClick={handleIncrease} style={{
                    cursor: 'pointer',
                    fontSize: '12px'

                }} />
            </Flex>
        </Flex>
    )
}

export default Counts;





const counterIconContainerStyle = {
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    p: '5px',
    px: '15px',
    bg: '#FFFFFF'
}

