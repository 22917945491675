import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import logo from './../../Assets/Images/logo.jpg'
import { signOut } from 'firebase/auth';
import { auth, db } from '../../firebase/init';
import { GrNotification } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/authContext';
import { collection, onSnapshot } from 'firebase/firestore';

const Header: React.FC = () => {
    const navigate = useNavigate();
    const [notificationsCount, setNotificationsCount] = useState(0);
    const { currentUser } = useContext<any>(Context)
    useEffect(() => {
        if (!currentUser) return

        const ref = collection(db, 'notifications',)
        onSnapshot(ref, (snapshot) => {
            let notifications: any = []
            snapshot.docs.forEach((el) => {
                notifications.push(el.data())
            })
            notifications = notifications.filter((el: any) => el.notifyTo.uid === currentUser.uid && !el.isSeen);
            setNotificationsCount(notifications.length)
        })

    }, [currentUser]);


    return (
        <Flex alignItems={'center'} justifyContent={'space-between'} mb={4} mt={5} >
            <Flex>
                <Image w={'30px'} src={logo} />
                <Text ml={'8px'} fontSize={'20px'} fontWeight={600}>BnBCheck</Text>
            </Flex>
            <Flex alignItems={'center'}>
                <Button onClick={() => navigate('/notifications')} variant={'ghost'} pos="relative">
                    <GrNotification style={{
                        fontSize: '23px'
                    }} />
                    <Flex cursor={'pointer'} alignItems={'center'} justifyContent={'center'} pos={'absolute'} top={0} right={'7px'} bg={'#00a1a3'} w={'20px'} borderRadius={'20px'} h={'20px'} fontSize={'14px'} color={'white'}>{notificationsCount}</Flex>
                </Button>
                <Menu >
                    <MenuButton >
                        <Image objectFit={'cover'} w={'45px'} height={'45px'} borderRadius={"100%"} src='https://images.pexels.com/photos/3748221/pexels-photo-3748221.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' />
                    </MenuButton>
                    <MenuList zIndex={9999}>
                        <MenuItem>Profile</MenuItem>
                        <MenuItem onClick={() => signOut(auth)}>Logout</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    )
}

export default Header;