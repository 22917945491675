import { Box, Text } from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase/init';

const NotificationItem: React.FC<any> = ({ createdAt, isSeen, type, createdByName, propertyInfo, id
}) => {
    const [shouldSeen, setShouldSeen] = useState(isSeen)

    useEffect(() => {
        if (isSeen) return;
        (async () => {
            const ref = doc(db, 'notifications', id);
            await updateDoc(ref, {
                isSeen: true
            })
        })()


    }, [])
    return (
        <Box p={3} borderBottom={'1px solid #8080802b'} bg={!shouldSeen ? '#e1e1e180' : ''} >
            <Text fontWeight={600}>
                Cleaning on  <Text cursor={'pointer'} as={'span'} color={'#00b3b3'}>
                    {propertyInfo.propertyName}
                </Text> is <Text as={'span'} color={'#4cd137'} mr={1}>

                    {type === 'completed' ? type : 'started'}
                </Text>
                by

                <Text cursor={'pointer'} as={'span'} ml={1} color={'#0984e3'}>
                    @{createdByName}
                </Text>

            </Text>

            <Text mt={1} fontSize={'15px'} fontWeight={500} color={'gray'}>
                {
                    moment(new Date(createdAt.seconds * 1000)).fromNow(true)
                }
                <Text ml={1} as={'span'}>

                    ago
                </Text>
            </Text>


        </Box>
    )
}

export default NotificationItem;




