import { isEven } from "./number";

export const secondsDifferenceBetweenTwoUnix = (tstart: any, tend: any) => {
    let diff = Math.floor((tend - tstart) / 1000)
    diff = Math.floor(diff / 1000);
    let secs_diff = diff % 60;
    return secs_diff;
}

const getTimeDiff = (start: number, end: number) => {
    return Math.floor((end - start) / 1000);
};
export const getTotalTime = (timeArr: any) => {
    let i = 0;
    let totalTime = 0;
    while (i < timeArr.length) {
        const start = timeArr[i];
        const end = timeArr[i + 1];
        if (!start || !end) {
            i = i + 2;
        } else {
            const diff = getTimeDiff(start, end);
            totalTime = totalTime + diff;
            i = i + 2;
        }

    }


    if (!isEven(timeArr.length)) {
        const diff = getTimeDiff(timeArr[timeArr.length - 1], new Date().getTime());
        totalTime = totalTime + diff;

    }

    return totalTime;
};


export const getFormattedTime = (totalSeconds: number) => {
    let hours = Number(String(totalSeconds / 3600).split('.')[0]);
    let seconds = Number(String(totalSeconds - (hours * 3600)).split('.')[0])
    let minitues = Number(String(seconds / 60).split('.')[0]);
    seconds = totalSeconds - (minitues * 60)

    return {
        hours,
        seconds,
        minitues
    }
}
