import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { db } from '../firebase/init';

export const Context = React.createContext({});




const ContextComponent: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<any>(null);




    useEffect(() => {
        const auth = getAuth()
        onAuthStateChanged(auth, async (user) => {
            // @ts-ignore
            if (user) {
                const ref = doc(db, 'users', user.uid);
                const snapshot = await getDoc(ref);
                setCurrentUser(snapshot.data())

            }
        })
    }, []);



    useEffect(() => {
        if (!currentUser) return;

        if (!currentUser.teams) {
            const ref = doc(db, 'users', currentUser.uid)
            onSnapshot(ref, (snapshot) => {
                console.log(snapshot.data())
                setCurrentUser(snapshot.data())
            })

        }
    }, [currentUser])

    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.replace(`${window.location.origin}/property`)
        }
    }, []);
    return (
        <Context.Provider value={{ currentUser: currentUser }}>
            {children}
        </Context.Provider>
    )
}




const useAuthContext = () => {
    const context: any = useContext(Context);

    if (context === undefined) {
        throw new Error('useAuthContext must be used within a useContextProvider')
    }

    return context
}

export { ContextComponent, useAuthContext };