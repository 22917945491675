import React from 'react'
import { Box, Button, Checkbox, Flex, Image, Text } from '@chakra-ui/react';
import CustomInput, { InputLabel } from '../../components/Forms/Input/Input';
import useForms from '../../hooks/useForms';
import useAsync from '../../hooks/useAsync';
import { googleLogin, login } from '../../firebase/auth';
import { useNavigate } from 'react-router-dom';

const INITIAL_VALUE = {
    email: '',
    password: ''
}

const getFirerbaseErrorMessage = (err: any) => {
    let erroCode = ''
    switch (err.code) {
        case 'auth/user-not-found': {
            erroCode = 'Wrong email or password';
            break;
        };

    };
    return erroCode
}

const Login: React.FC = () => {
    const { handleChange, inputsState } = useForms(INITIAL_VALUE);
    const { email, password } = inputsState;
    const navigate = useNavigate()
    const handleLogin = async () => {

        try {
            await login(inputsState)
            window.location.replace(`${window.location.origin}/property`);
        } catch (err) {
            console.log(err)
        }
    };
    const { execute, status, value, error } = useAsync(handleLogin, false);
    const { execute: googleExecute, status: googleAuthStatus, value: userValue, error: googleError } = useAsync(googleLogin, false);

    return (
        <Box px={'30px'}>
            <Box mt={'30px'}>
                <Text fontWeight={600} fontSize={'24px'} >Hello! Welcome back!  👋</Text>
                <Text color={'#A8A2A2'} fontSize="15px">Hello again, You’ve been missed! </Text>
            </Box>

            <Box mt={'30px'}>
                <CustomInput value={email} handleChange={handleChange} name="email">
                    <InputLabel label='Email Address' />
                </CustomInput>
                <CustomInput value={password} handleChange={handleChange} name="password">
                    <InputLabel label='Password' />
                </CustomInput>
                <Flex justifyContent={'space-between'} mt="20px" mb={'35px'}>
                    <Flex>
                        <Checkbox />
                        <Text ml={3}>Remember me</Text>
                    </Flex>
                    <Text color={'#FF0000'} fontSize="15px" >Forgot Password</Text>
                </Flex>

                <Button w={'100%'} height="44px" onClick={execute} isLoading={status === 'pending'} >Login</Button>
                {
                    (error || googleError) && (

                        <Text mt={3} textTransform="capitalize" color={'red'}>
                            {
                                // @ts-ignore
                                error ? getFirerbaseErrorMessage(error) : getFirerbaseErrorMessage(googleError)
                            }
                        </Text>
                    )
                }
            </Box>
            <Flex alignItems="center" justifyContent={'space-between'} my={'25px'}>
                <hr style={{ width: '30%', borderWidth: '1px' }} />
                <Text fontSize={'14px'} fontWeight={500}>Or Login with</Text>
                <hr style={{ width: '30%', borderWidth: '1px' }} />
            </Flex>
            <Text>Or</Text>
            <Button onClick={() => navigate('/signup')}>Signup</Button>
            <Box mt={4}>
                <Button onClick={googleExecute} isLoading={googleAuthStatus === 'pending'} w={'100%'} height="44px" variant={'outline'} >
                    <Flex align={'center'} >
                        <Image w={'25px'} src='https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png' alt='google logo' />
                        <Text ml={2}>Google</Text>
                    </Flex>
                </Button>
            </Box>
        </Box>
    )
}

export default Login;

