import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import useAsync from '../../hooks/useAsync';
import { getAllCleaners, getAllCleanings, updateCleaning } from '../../firebase/Cleanings';
import Header from '../../components/UI/Header';
import Segment from '../../components/UI/Segment';
import { BsFilterRight } from 'react-icons/bs';
import { MdReportProblem } from 'react-icons/md';
import ActionBox from '../../components/UI/ActionBox';
import ActionSheet from 'actionsheet-react';
import { BiChevronDown, BiChevronLeft } from 'react-icons/bi';
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../firebase/init';
import { isEven } from '../../Helper/number';
import { getFormattedTime, getTotalTime } from '../../Helper/time';
import { DateRange } from 'react-date-range';
import { getFilteredCleanings } from '../Cleanings';
import { Context } from '../../context/authContext';
import { Layout } from '../../components/Layout/Layout';

const getFormattedDate = (range: any) => {
    const rangeObject = range[0];
    if (rangeObject.endDate === null) return 'Date';
    let startDateRef = new Date(rangeObject.startDate);
    let endDateRef = new Date(rangeObject.endDate);
    return `(${startDateRef.getFullYear()}-${startDateRef.getMonth() + 1}-${startDateRef.getDate()} -- ${endDateRef.getFullYear()}-${endDateRef.getMonth() + 1}-${endDateRef.getDate()})`
}

const isObjectEmpty = (obj: any) => {
    return !Object.keys(obj).length
}


const getStatusComponent = (status: string, timeArr: any) => {
    let textStyle = {
        ml: 1,
        fontWeight: 500,
        fontSize: '18px',
        textTransform: 'capitalize'
    }
    if (status === 'started' && !isEven(timeArr[Object.keys(timeArr)[0]].time)) {
        return (
            <Text sx={textStyle} color="#38B87C">
                {'Paused'}
            </Text>
        )
    } else if (status === 'working') {
        return (
            <Text sx={textStyle} color="#EBAF3E">
                {status}
            </Text>
        )
    } else if (status === 'completed') {
        return (
            <Text sx={textStyle} color="#38B87C">
                {status}
            </Text>
        )
    } else {
        return (
            <Text sx={textStyle} color="gray">
                not started
            </Text>

        )
    }

}

const ReportItem: React.FC<any> = ({ assignedCleaner, status, endTime, startedTime, timeArray, reports, id
}) => {

    const [seconds, setSeconds] = useState(0);
    const [isWorking, setIsWorking] = useState(false)

    let lightText = {
        color: '#9E9E9E',
        fontWeight: '400',
        fontSize: '18px'
    }
    console.log(timeArray, id)

    useEffect(() => {

        if (status === 'completed') {
            setSeconds(getTotalTime(timeArray[Object.keys(timeArray)[0]].time))
            return
        }
        // If Cleaning Already Have started Tracking Time

        let tr = timeArray
        if (tr) {

            if (!isEven(tr[Object.keys(tr)[0]].time.length)) {
                const timeArrayOfUser = tr[Object.keys(tr)[0]].time
                let workedTime = getTotalTime(timeArrayOfUser);
                setSeconds(workedTime)

            } else if (isEven(tr[Object.keys(tr)[0]].time.length)) {
                console.log('i am get called')
                const timeArrayOfUser = tr[Object.keys(tr)[0]].time
                let workedTime = getTotalTime(timeArrayOfUser);
                setSeconds(workedTime)
            }
        }

    }, [])
    useEffect(() => {
        let interval: any = () => '';

        if (timeArray && !isEven(timeArray[Object.keys(timeArray)[0]].time.length)) {
            setIsWorking(true)
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);

        } else if (timeArray && isEven(timeArray[Object.keys(timeArray)[0]].time.length)) {
            setIsWorking(false)
            clearInterval(interval)

        }

        return () => {
            clearInterval(interval)
        }
    }, [timeArray]);
    let timeObject = getFormattedTime(seconds);


    const handleResolve = async () => {

        const reportObject = { ...reports };

        reportObject[Object.keys(reportObject)[0]].flagged = 'resolved'

        try {
            const ref = doc(db, 'cleanings', id);
            await updateDoc(ref, {
                reports: reportObject
            })
        } catch (err) {
            console.log(err)

        }



    };




    return (
        <Box mb={'14px'} bg={'white'} borderRadius={'8px'} p={'16px'}>
            <Text textTransform={'capitalize'} fontSize={'18px'} fontWeight={600} color={'#00A1A3'}>{assignedCleaner.displayName}</Text>
            <Text mt={'12px'} sx={lightText}>Total worked time: </Text>
            <Text mb={'12px'} sx={lightText} >
                {timeObject.hours} hours
                <Text mx={3} as="span">
                    {timeObject.minitues} minitues
                </Text>
                {timeObject.seconds} seconds

            </Text>
            <Flex alignItems={'center'} mb={'12px'}>
                <Text sx={lightText}>Status:</Text>
                {getStatusComponent(isWorking ? 'working' : status, timeArray)}
            </Flex>
            {
                reports && (
                    <>
                        <Divider />

                        <Flex alignItems={'center'} my={'10px'} fontSize={'18px'} >
                            <MdReportProblem color='red' fontSize={'20px'} />
                            <Text ml={2}>Report: </Text>
                            <Text ml={1} fontWeight={600} color={'#F04847'} textTransform="capitalize"  >
                                {
                                    reports && reports[Object.keys(reports)[0]].flagged
                                }
                            </Text>
                        </Flex>
                        <Button onClick={handleResolve} height={'42px'} borderRadius={'8px'} w="100%" mt={2} bg={'none'} border={'1px solid #2D2C31'}>
                            Make it resolve
                        </Button>
                    </>
                )
            }


        </Box>
    )
}



const Reports: React.FC = () => {
    const [currentSelectedSegment, setCurrentSelectedSegment] = useState('cleaners');
    const { value: cleaners, setValue } = useAsync(getAllCleaners);
    const [reports, setReports] = useState<any>(null);
    const [cleanings, setCleanings] = useState<any>(null);
    const currentDate = new Date();
    const oneWeekFromNow = new Date((new Date()).setDate(currentDate.getDate() + 6));
    const { currentUser } = useContext<any>(Context);

    const [filteredState, setFilteredState] = useState<any>({
        range: [
            {
                startDate: new Date(),
                endDate: oneWeekFromNow,
                key: 'selection'
            }
        ],
        selectedCleaner: {}
    });

    const { range, selectedCleaner } = filteredState;

    const ref = useRef();
    const handleOpen = () => {
        // @ts-ignore
        ref.current.open();
    };

    const handleClose = () => {
        // @ts-ignore
        ref.current.close();
    };



    const handleReset = () => { }


    useEffect(() => {

        if (!currentUser || !currentUser.teams) return;

        (async () => {

            const q = query(collection(db, "cleanings"), where('teamId', '==', currentUser.teams[0]))
            onSnapshot(q, (querySnapshot) => {
                let cleanings: any = [];

                querySnapshot.docs.forEach((el) => {
                    cleanings.push(el.data())
                })

                let formattedObject: any = {
                    cleaners: {},
                    properties: {},
                }

                let reportsData = getFilteredCleanings(cleanings, range[0]);

                cleanings.forEach((cleaning: any) => {
                    const assignedProperty = cleaning.assignedProperty;
                    const assignedCleaner = cleaning.assignedCleaner;
                    let newReport: any = {};

                    Object.keys(reportsData).forEach((el) => {
                        const currentDateReport = reportsData[el];
                        let assignedCleanerCleaning = currentDateReport.cleaning.filter((el: any) => el.assignedCleaner.uid === assignedCleaner.uid);
                        newReport[el] = {
                            cleaning: assignedCleanerCleaning
                        }

                    })




                    formattedObject.cleaners[assignedCleaner.uid] = {
                        reports: newReport,
                        name: assignedCleaner.displayName
                    }
                    let newPropertyReport: any = {};

                    Object.keys(reportsData).forEach((el) => {
                        const currentDateReport = reportsData[el];
                        let assignedPropertyCleaning = currentDateReport.cleaning.filter((el: any) => el.assignedProperty.id === assignedProperty.id);
                        newPropertyReport[el] = {
                            cleaning: assignedPropertyCleaning
                        }

                    })

                    formattedObject.properties[assignedProperty.id] = {
                        reports: newPropertyReport,
                        name: assignedProperty.propertyName

                    }
                })


                setReports(formattedObject)
                setCleanings(cleanings)
            });

        })()
    }, [currentUser])


    const menuButtonStyle = {
        bg: 'none',
        border: '1px solid #E5E5E5',
        borderRadius: '6px',
        height: '47px',
    }

    const menuButtonText = {
        fontSize: '15px',
        color: '#9E9E9E',
        textAlign: 'left'
    }

    const menuItemHeading = {
        fontSize: '15px',
        color: '#2D2C31',
        fontWeight: '500'
    }
    const menuSubHeading = {
        fontSize: '14px',
        color: '#2D2C31',
        fontWeight: '300'
    }



    const handleFilter = () => {
        let formattedObject: any = {
            cleaners: {},
            properties: {},
        }

        cleanings.forEach((cleaning: any) => {
            const assignedProperty = cleaning.assignedProperty;
            const assignedCleaner = cleaning.assignedCleaner;
            let reportsData = getFilteredCleanings(cleanings, range[0]);

            let newReport: any = {};

            Object.keys(reportsData).forEach((el) => {
                const currentDateReport = reportsData[el];
                let assignedCleanerCleaning = currentDateReport.cleaning.filter((el: any) => el.assignedCleaner.uid === assignedCleaner.uid);
                newReport[el] = {
                    cleaning: assignedCleanerCleaning
                }

            })
            formattedObject.cleaners[assignedCleaner.uid] = {
                reports: newReport,
                name: assignedCleaner.displayName
            }

            let newPropertyReport: any = {};

            Object.keys(reportsData).forEach((el) => {
                const currentDateReport = reportsData[el];
                let assignedPropertyCleaning = currentDateReport.cleaning.filter((el: any) => el.assignedProperty.id === assignedProperty.id);
                newPropertyReport[el] = {
                    cleaning: assignedPropertyCleaning
                }

            })


            formattedObject.properties[assignedProperty.id] = {
                reports: newPropertyReport,
                name: assignedProperty.propertyName

            }
        })



        setReports(formattedObject)

    }

    console.log({ reports })

    return (
        <Layout>

            <Box pt={'40px'} height={'100vh'} >
                <Box mb={'16px'}>
                    <Segment value={currentSelectedSegment} onSelect={(val: string) => setCurrentSelectedSegment(val)} />
                </Box>
                <Flex alignItems={'center'} justifyContent={'flex-end'}>
                    <Button onClick={handleOpen} bg={'white'} w={'50px'} h={'50px'} px={'0px'} >
                        <BsFilterRight fontSize={'20px'} />
                    </Button>
                </Flex>
                <Box pb={'200px'}>
                    <Text fontWeight={500} fontSize={'20px'}>All reports</Text>

                    {
                        reports && Object.keys(reports[currentSelectedSegment]).length && Object.keys(reports[currentSelectedSegment]).map((key: any) => (
                            <Box>
                                <Text fontWeight={'700'} color={'#00a1a3'} fontSize={'24px'} my={3} textTransform="capitalize" >{reports[currentSelectedSegment][key].name}</Text>
                                {
                                    Object.keys(reports[currentSelectedSegment][key].reports).map((dateKey: any) => (

                                        <Box>
                                            {
                                                reports[currentSelectedSegment][key].reports[dateKey].cleaning.length ? (
                                                    <Text fontWeight={600} fontSize="20px" mb={4} >{dateKey}</Text>
                                                ) : ''
                                            }
                                            {
                                                reports[currentSelectedSegment][key].reports[dateKey].cleaning.map((el: any) => (
                                                    <ReportItem {...el} />
                                                ))
                                            }

                                        </Box>

                                    ))
                                }
                            </Box>
                        ))
                    }

                </Box>
                <ActionSheet mouseEnable={false} ref={ref} bgStyle={{
                    background: 'rgba(0, 0, 0, 0.51)'
                }} sheetStyle={{
                    height: '75vh',
                    background: '#F5F5F5'
                }}>

                    <Flex justifyContent={'space-between'} flexDir={'column'} px={'25px'} py={'30px'}>
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                            <BiChevronLeft fontSize={'30px'} />
                            <Text fontSize={'20px'} fontWeight={500}>Filter</Text>
                            <Button onClick={handleReset} variant={'ghost'} color={'#7D7D7D'}>Reset</Button>
                        </Flex>
                        <Box mt={'40px'}>
                            <Menu matchWidth>
                                <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                                    <Text sx={menuButtonText}>
                                        {
                                            isObjectEmpty(selectedCleaner) ? 'All Cleaners' : selectedCleaner.displayName
                                        }
                                    </Text>
                                </MenuButton>
                                <MenuList>
                                    {
                                        cleaners?.map((el: any) => (
                                            <MenuItem onClick={() => setFilteredState({
                                                ...filteredState,
                                                selectedCleaner: el
                                            })}>
                                                <Flex w={'100%'} justifyContent={'space-between'}>
                                                    <Box>
                                                        <Text sx={menuItemHeading}>
                                                            {el.displayName}
                                                        </Text>
                                                        <Text sx={menuSubHeading}>{el.email}</Text>
                                                    </Box>

                                                </Flex>
                                            </MenuItem>
                                        ))
                                    }

                                </MenuList>
                            </Menu>
                        </Box>

                        <Box  >
                            <Menu >
                                <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                                    <Text sx={menuButtonText}>
                                        {getFormattedDate(range)}
                                    </Text>
                                </MenuButton>
                                <MenuList >
                                    <DateRange

                                        editableDateInputs={true}
                                        // @ts-ignore
                                        onChange={item => setFilteredState({
                                            ...filteredState,
                                            range: [item.selection]
                                        })}
                                        moveRangeOnFirstSelection={false}
                                        // @ts-ignore

                                        ranges={range}
                                    />
                                </MenuList>
                            </Menu>
                        </Box>
                        <Button onClick={handleFilter} bg={'#00ACAF'} w={'100%'} color="white" mt={6}>
                            Apply filter
                        </Button>
                    </Flex>

                </ActionSheet>
            </Box>
        </Layout>
    )
}

export default Reports;
