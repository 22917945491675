
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import './init'
import { createTeam, addMemberToTeam, updateInvitationsStatus } from './team';


const provider = new GoogleAuthProvider();

const getOrCreateUser = async (user: any, inviationObj: any = {}) => {

    console.log({ user, inviationObj })
    const db = getFirestore();
    // @ts-ignore
    const ref = doc(db, 'users', user.uid);
    const snapshot = await getDoc(ref);

    console.log({
        isExist: snapshot.exists()
    })
    if (snapshot.exists()) {
        return snapshot.data()
    } else {

        try {

            if (inviationObj.invitationId) {

                await addMemberToTeam({
                    ...inviationObj,
                    displayName: user.displayName,
                    avatar: '',
                    uid: user.uid
                })
                await setDoc(ref, {
                    ...user,
                    teams: [inviationObj.teamId]
                })
                await updateInvitationsStatus(inviationObj.invitationId)
            } else {


                const teamId = await createTeam({
                    member: {
                        roles: ['Owner'],
                        email: user.email,
                        displayName: user.displayName,
                        avatar: '',
                        uid: user.uid,

                    },
                    createdBy: user.uid
                })
                await setDoc(ref, {
                    ...user,
                    teams: [teamId]
                })

            }
        } catch (err) {
            console.log({ err })
        }




    }

}

export const signupUser = async (userObject: any, invitationState?: any, invitationId?: any) => {
    const auth = getAuth();
    const { email, password } = userObject;
    const { user } = await createUserWithEmailAndPassword(auth, email, password);

    try {

        await getOrCreateUser({
            uid: user.uid,
            ...userObject
        }, {
            ...invitationState,
            invitationId
        })
    } catch (err) {
        console.log({ err })
    }

}



export const login = async (userCredential: any) => {
    const { email, password } = userCredential;
    const auth = getAuth()
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user
}


export const googleLogin = async () => {
    const auth = getAuth();
    const { user } = await signInWithPopup(auth, provider)
    return user;
}   