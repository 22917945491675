import { Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import React, { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi';

import { DateRange } from 'react-date-range';

interface CleaningFilterProps {
    filterState: any;
    properties: any;
    cleaners: any,
    handleFilterState: any;
    handleFilterSubmit: any
}

const isObjectEmpty = (obj: any) => {
    return !Object.keys(obj).length
}

const getFormattedDate = (range: any) => {
    const rangeObject = range[0];
    if (rangeObject.endDate === null) return 'Date';
    let startDateRef = new Date(rangeObject.startDate);
    let endDateRef = new Date(rangeObject.endDate);
    return `(${startDateRef.getFullYear()}-${startDateRef.getMonth() + 1}-${startDateRef.getDate()} -- ${endDateRef.getFullYear()}-${endDateRef.getMonth() + 1}-${endDateRef.getDate()})`
}

const CleaningFilter: React.FC<CleaningFilterProps> = ({ properties, cleaners, handleFilterState, filterState, handleFilterSubmit }) => {

    const { range, selectedCleaner, selectedProperty, status } = filterState;

    const menuButtonStyle = {
        bg: 'none',
        border: '1px solid #E5E5E5',
        borderRadius: '6px',
        height: '47px',
    }

    const menuButtonText = {
        fontSize: '15px',
        color: '#9E9E9E',
        textAlign: 'left'
    }

    const menuItemHeading = {
        fontSize: '15px',
        color: '#2D2C31',
        fontWeight: '500'
    }
    const menuSubHeading = {
        fontSize: '14px',
        color: '#2D2C31',
        fontWeight: '300'
    }




    return (
        <Box>
            <Box>
                <Menu >
                    <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                        <Text sx={menuButtonText}>
                            {getFormattedDate(range)}
                        </Text>
                    </MenuButton>
                    <MenuList >
                        <DateRange

                            editableDateInputs={true}
                            // @ts-ignore
                            onChange={item => handleFilterState({
                                ...filterState,
                                range: [item.selection]
                            })
                            }
                            moveRangeOnFirstSelection={false}
                            // @ts-ignore

                            ranges={range}
                        />
                    </MenuList>
                </Menu>
            </Box>
            <Box>
                <Menu matchWidth>
                    <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                        <Text sx={menuButtonText}>
                            {
                                isObjectEmpty(selectedCleaner) ? 'All Team members' : selectedCleaner.displayName
                            }
                        </Text>
                    </MenuButton>
                    <MenuList >
                        {
                            cleaners?.map((el: any) => (
                                <MenuItem onClick={() => handleFilterState({
                                    ...filterState,
                                    selectedCleaner: el
                                })}>
                                    <Box>
                                        <Text sx={menuItemHeading}>{el.displayName}</Text>
                                        <Text mt={1} sx={menuSubHeading}>{el.email}</Text>
                                    </Box>
                                </MenuItem>
                            ))
                        }
                    </MenuList>
                </Menu>
            </Box>
            <Box>
                <Menu matchWidth>
                    <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                        <Text sx={menuButtonText}>
                            {
                                isObjectEmpty(selectedProperty) ? 'All Properties' : selectedProperty.propertyName
                            }
                        </Text>
                    </MenuButton>
                    <MenuList>
                        {
                            properties?.map((el: any) => (
                                <MenuItem onClick={() => handleFilterState({
                                    ...filterState,
                                    selectedProperty: el
                                })}>
                                    <Flex w={'100%'} justifyContent={'space-between'}>
                                        <Box>
                                            <Text sx={menuItemHeading}>
                                                {el.propertyName}
                                            </Text>
                                            <Text sx={menuSubHeading}>Access Code: 12345</Text>
                                        </Box>
                                        <Image borderRadius={'6px'} w={'40px'} height={'40px'} src={'https://images.pexels.com/photos/210617/pexels-photo-210617.jpeg?cs=srgb&dl=pexels-pixabay-210617.jpg&fm=jpg'} />
                                    </Flex>
                                </MenuItem>
                            ))
                        }

                    </MenuList>
                </Menu>
            </Box>
            <Box>
                <Menu matchWidth>
                    <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                        <Text sx={menuButtonText}>
                            {status === '' ? 'Status' : status}
                        </Text>
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => handleFilterState({
                            ...filterState,
                            status: 'assigned'
                        })} >Assigned</MenuItem>
                        <MenuItem onClick={() => handleFilterState({
                            ...filterState,
                            status: 'unassigned'
                        })}>Unassigned</MenuItem>

                    </MenuList>
                </Menu>
            </Box>

            <Button onClick={handleFilterSubmit} fontSize={'15px'} borderColor={'#00ACAF'} color={'#9E9E9E'} height={'45px'} w={'100%'} variant={'outline'}>
                Apply Filter
            </Button>
        </Box>
    )
}


export default CleaningFilter;
