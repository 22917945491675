import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import { useOutsideClick } from '@chakra-ui/react'

import Counts from '../../components/Property/Counts';
import { v4 as uuidv4 } from 'uuid';

import AutoComplete from 'react-google-autocomplete'
import { AiOutlinePlus } from 'react-icons/ai';
import CleaningItem from '../Cleanings/CleaningItem';
import StepInput from '../Cleanings/StepInput';
import Inputs from '../../components/UI/Inputs';
import { useLocationSuggestions } from '../../hooks/useLocationSuggestions';
import { BiChevronDown } from 'react-icons/bi';
import { getTeam } from '../../firebase/team';
import { Context } from '../../context/authContext';
import { uploadPropertyImages } from '../../firebase/upload';

interface PropertiesFormType {
    actionType?: 'create' | 'edit',
    formData: any,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleAction: () => void;
    isLoading?: boolean;
    cb: any
}

const PropertiesForm: React.FC<PropertiesFormType> = ({ formData, actionType, handleChange, handleAction, isLoading, cb }) => {
    const [imageUploading, setImageUploading] = useState(false)
    const [owners, setOwners] = useState([])
    const { propertyName, propertyDescription, addressText, bathrooms, bedrooms, checkIn, checkOut, accessCode, steps, ownerInfo, images } = formData;
    const { show, suggestions, setShow, getLocation } = useLocationSuggestions(addressText)
    const ref = useRef(null);
    const { currentUser } = useContext<any>(Context)
    useOutsideClick({
        ref: ref,
        handler: () => setShow(false)
    })
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleInputChange = (name: string, value: any) => {

        handleChange({
            // @ts-ignore
            target: {
                name: name,
                value: value
            }
        })
    }

    const handleStepsAdd = (val: string) => {
        const id = uuidv4();
        const newSteps = { ...steps };
        newSteps[id] = {
            id,
            title: val,
            tasks: []
        }

        handleChange({
            /* @ts-ignore */
            target: {
                name: 'steps',
                value: newSteps
            }
        })
        onClose()
    }

    const handleTaskAdd = (id: string, taskVal: {}, cb: () => void) => {
        const newSteps = { ...steps };
        const currentStep = newSteps[id];

        currentStep.tasks = [
            ...currentStep.tasks,
            // @ts-ignore
            { ...taskVal, isCompleted: false, id: uuidv4() }
        ]
        newSteps[id] = currentStep;
        handleChange({
            /* @ts-ignore */
            target: {
                name: 'steps',
                value: newSteps
            }
        })

        cb()

    }


    const handleTaskUpdate = (id: string, taskId: string, value: any) => {
        const newSteps = { ...steps };
        const currentStep = newSteps[id];
        const tasks = currentStep.tasks;

        const itemWillRemovedIdx = tasks.findIndex((el: any) => el.id === taskId);

        tasks.splice(itemWillRemovedIdx, 1, value)
        handleChange({
            /* @ts-ignore */
            target: {
                name: 'steps',
                value: newSteps
            }
        })
    }


    const handleDelete = (taskId: string, stepId: string) => {


        const newSteps = { ...steps };
        const currentStep = newSteps[stepId];
        const tasks = currentStep.tasks;

        const filteredTask = tasks.filter((el: any) => el.id !== taskId);
        newSteps[stepId].tasks = filteredTask;
        handleChange({
            /* @ts-ignore */
            target: {
                name: 'steps',
                value: newSteps
            }
        })


    }

    const handleClick = (address: any) => {

        cb({
            addressText: address.place_name,
            coordinates: address.center
        })
        setShow(false)

    }

    let buttonStyle = {
        bg: '#00ACAF',
        color: 'white',
        height: '45px',
        fontWeight: 500,
        fontSize: '15px',
        marginTop: '30px'
    }

    const menuButtonStyle = {
        bg: 'none',
        border: '1px solid #E5E5E5',
        borderRadius: '6px',
        height: '47px',
    }

    const menuButtonText = {
        fontSize: '15px',
        color: '#9E9E9E',
        textAlign: 'left'
    }

    const menuItemHeading = {
        fontSize: '15px',
        color: '#2D2C31',
        fontWeight: '500'
    }
    const menuSubHeading = {
        fontSize: '14px',
        color: '#2D2C31',
        fontWeight: '300'
    }


    useEffect(() => {

        (async () => {
            const team: any = await getTeam(currentUser.teams[0]);
            const owners = team.members.filter((el: any) => el.roles.includes('Owner'));

            setOwners(owners)

        })()

        return () => {

        }
    }, [])


    const handleImageUpload = async (files: any) => {
        setImageUploading(true)
        let promise: any = []
        Object.keys(files).forEach((el) => {
            promise.push(uploadPropertyImages(files[el]));
        })

        const urls = await Promise.all(promise)

        handleChange({
            /* @ts-ignore */
            target: {
                name: 'images',
                /* @ts-ignore */
                value: urls
            }
        })
        setImageUploading(false)

    }


    return (
        <Box pb={5}>
            <Text color={'#2C2C30'} fontSize="22px" fontWeight={600}>Add properties</Text>

            <Box mt={'20px'} >

                <Box bg={'white'} borderRadius="8px" mb={'14px'}>

                    <Inputs

                        isError={false}
                        onChange={(e: any) => {
                            handleChange({
                                /* @ts-ignore */
                                target: {
                                    name: 'propertyName',
                                    value: e.value
                                }
                            })
                        }}
                        value={propertyName}
                        name="propertyName"
                        placeholder='Property title'
                        primaryStyles={{}}
                    />

                </Box>
                <Box ref={ref} pos={'relative'} bg={'white'} borderRadius="8px" mb={'14px'}>
                    <Inputs
                        isError={false}
                        onChange={(e: any) => {

                            getLocation()
                            /* @ts-ignore */
                            handleChange({
                                target: e
                            })
                        }}
                        value={addressText}
                        name={'addressText'}
                        placeholder='Address'
                        primaryStyles={{}}
                    />
                    {
                        show && (

                            <Box cursor={'pointer'} px={'10px'} pt={2} pos="absolute" top={'65px'} left={0} bg={"white"} zIndex={1212} w={'100%'} borderRadius={'12px'} boxShadow={' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'} >
                                {
                                    suggestions.map((el: any) => (
                                        <Flex
                                            onClick={() => handleClick(el)}
                                            py={'8px'}

                                            borderBottom={'1px solid #E5E5E5'}>
                                            {el.place_name}
                                        </Flex>
                                    ))
                                }
                            </Box>

                        )
                    }

                </Box>
                <Box bg={'white'} borderRadius="8px" mb={'14px'}>

                    <Inputs
                        isError={false}
                        onChange={(e: any) => {
                            handleChange({
                                /* @ts-ignore */
                                target: {
                                    name: 'accessCode',
                                    value: e.value
                                }
                            })
                        }}
                        value={accessCode}
                        placeholder='Access  code'
                        primaryStyles={{}}
                    />

                </Box>

                <Box>
                    <Text fontWeight={600} fontSize={'18px'} mb={2}>Select Owner</Text>

                    <Menu matchWidth>
                        <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                            <Text sx={menuButtonText}>
                                {ownerInfo.displayName ? ownerInfo.displayName : 'Select Owner'}
                            </Text>
                        </MenuButton>
                        <MenuList>
                            {
                                owners?.map((el: any) => (
                                    <MenuItem onClick={() => handleChange({
                                        /* @ts-ignore */
                                        target: {
                                            name: 'ownerInfo',
                                            /* @ts-ignore */

                                            value: el
                                        }
                                    })}  >
                                        <Flex w={'100%'} justifyContent={'space-between'}>
                                            <Box>
                                                <Text sx={menuItemHeading}>
                                                    {el.displayName}
                                                </Text>
                                            </Box>

                                        </Flex>
                                    </MenuItem>
                                ))
                            }

                        </MenuList>
                    </Menu>
                </Box>

                <Box borderRadius="8px" mb={'14px'}>
                    <Text fontWeight={600} fontSize={'18px'} mb={2}>Property description</Text>
                    <Textarea borderRadius={'12px'} bg={'white'} minH={'150px'} >
                    </Textarea>

                </Box>

                <Box mb={'14px'}>
                    <Text fontWeight={600} fontSize={'18px'} mb={2}>Upload Images</Text>
                    <input type={'file'} multiple onChange={(e) => handleImageUpload(e.target.files)} />

                    {
                        imageUploading && (
                            <Box>
                                <Spinner mt={1} />
                            </Box>
                        )
                    }
                    <HStack mt={2}>
                        {
                            images.map((el: string) => <Image src={el} w={'70px'} h={'70px'} />)
                        }
                    </HStack>


                </Box>

                <Box>
                    {/*  @ts-ignore */}

                    <Counts
                        label='Bathrooms'
                        value={bathrooms}
                        setValue={(val) => handleInputChange('bathrooms', val)}
                    />
                    {/*  @ts-ignore */}
                    <Counts
                        label='Bedrooms'
                        value={bedrooms}
                        setValue={(val) => handleInputChange('bedrooms', val)}
                    />
                </Box>



                {/* Steps */}
                <Box mt={7}>
                    <Flex alignItems={'center'} justifyContent="space-between">
                        <Text fontWeight={600} fontSize={'17px'}>Cleaning Steps</Text>
                        <Button px={6} fontSize={'15px'} bg={'#E6E6E6'} onClick={onOpen}>
                            <AiOutlinePlus />
                            <Text ml={2}>
                                Add Step
                            </Text>
                        </Button>
                    </Flex>

                    {
                        Object.keys(steps).map((id: string, idx: number) =>
                            <CleaningItem
                                idx={idx}
                                {...steps[id]}
                                handleAction={handleTaskAdd}
                                handleTaskUpdate={handleTaskUpdate}
                                handleDelete={handleDelete}
                            />)
                    }


                    {
                        isOpen && (

                            <StepInput
                                label='Add Steps'
                                handleAction={handleStepsAdd}
                            />
                        )
                    }

                </Box>
            </Box>

            <Button isLoading={isLoading} w={'100%'} mt={3} onClick={handleAction} sx={buttonStyle} >Create Property</Button>

        </Box>
    )
}


export default PropertiesForm;
