import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import ActionSheet from 'actionsheet-react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AiFillDelete, AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { BiChevronDown, BiChevronLeft } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { Radio } from '../../components/Cleaning/Upload';
import { Layout } from '../../components/Layout/Layout';
import Header from '../../components/UI/Header';
import Inputs from '../../components/UI/Inputs';
import { Context } from '../../context/authContext';
import { db } from '../../firebase/init';
import { getTeam, sentInvitation, getTeamInvitations } from '../../firebase/team';
import useAsync from '../../hooks/useAsync';


let boldText = {
    fontSize: '22px',
    fontWeight: 600
};
const MemberItem: React.FC<any> = ({ displayName, email, avatar, handleDeleteTeamMember, uid }) => {
    return (
        <Flex justifyContent={'space-between'} pb={3} pt={2} my={3} borderBottom="1px solid #e3e3e3" >
            <Flex>
                <Image w={'45px'} h="45px" borderRadius={'100%'} src={avatar} fallbackSrc='https://via.placeholder.com/150' alt='team-member' />
                <Box ml={4} >
                    <Text sx={{
                        ...boldText,
                        fontSize: '16px'
                    }}>{displayName}</Text>
                    <Text fontSize={'14px'} fontWeight={500} color="#9E9E9E" >{email}</Text>
                </Box>
            </Flex>

            <Menu>
                <MenuButton
                    aria-label='Options'
                >

                    <BsThreeDotsVertical />
                </MenuButton>
                <MenuList>
                    <MenuItem icon={<AiFillDelete />} onClick={() => handleDeleteTeamMember(uid)} >
                        Delete
                    </MenuItem>

                </MenuList>
            </Menu>
        </Flex>
    )
}

const Team = () => {
    const [searchText, setSearchText] = useState('')
    const [teamInvitations, setTeamInvitations] = useState<any>([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { teamId } = useParams<any>();
    const { currentUser } = useContext<any>(Context)
    const { value: team, execute, status, setValue } = useAsync(async () => getTeam(teamId))
    const [memberCredentials, setmemberCredentials] = useState<any>({
        email: '',
        roles: []
    });

    const { email, roles } = memberCredentials;

    const ref = useRef();
    const navigate = useNavigate();
    const handleOpen = () => {
        // @ts-ignore
        ref.current.open();
    };

    const handleClose = () => {
        // @ts-ignore
        ref.current.close();
    };
    const menuButtonStyle = {
        border: '1px solid #E5E5E5',
        borderRadius: '6px',
        height: '47px',

    }



    const handleSelect = (value: string) => {

        const isExist = roles.filter((el: string) => el === value).length;
        let newRoles: string[] = [...roles];
        if (isExist) {
            newRoles = newRoles.filter((el) => el !== value);

            setmemberCredentials({
                ...memberCredentials,
                roles: newRoles
            })
        } else {
            newRoles.push(value);
            setmemberCredentials({
                ...memberCredentials,
                roles: newRoles
            })
        }

    }


    const handleInvitation = async () => {

        await sentInvitation({
            sentBy: currentUser.uid,
            email,
            roles,
            name: team.name,
            teamId: team.teamId,
        }, (obj: any) => setTeamInvitations([...teamInvitations, obj]))

        setmemberCredentials({
            email: '',
            roles: []
        })
        handleClose();
    }

    const { execute: invitationExecute, status: invitationStatus, error } = useAsync(handleInvitation, false);;


    useEffect(() => {
        if (!currentUser) return;


        (async () => {
            const invitations = await getTeamInvitations(currentUser.teams[0]);

            const filetered = invitations.filter((el: any) => el.status !== 'accepted')
            setTeamInvitations(filetered)
        })()
    }, [currentUser]);


    const handleDeleteInvitations = async (invitationId: string) => {
        let newInv = [...teamInvitations];
        newInv = newInv.filter((el) => el.id !== invitationId);

        setTeamInvitations(newInv);


        const invRef = doc(db, 'invitations', invitationId);
        await deleteDoc(invRef)
    };

    const handleDeleteTeamMember = async (uid: string) => {
        let newTeam = { ...team };
        let members = newTeam.members;

        members = members.filter((el: any) => el.uid !== uid);
        newTeam.members = members
        setValue(newTeam);

        const ref = doc(db, 'teams', currentUser.teams[0])
        await updateDoc(ref, newTeam)
    }

    return (
        <Layout>
            <Box bg={'#F7F7F7'} height={'100vh'} fontFamily="Poppins" overflowY={'auto'} >
                <Box>

                    <Box>
                        <Text my={6} mt={3} sx={boldText} >Team</Text>

                        <Flex>

                            <InputGroup bg={'white'}   >
                                <Input _placeholder={{
                                    fontSize: '14px'
                                }} onChange={(e) => setSearchText(e.target.value)} value={searchText} placeholder='Search members' py={6} />
                                <InputRightElement mt={'4px'} children={<AiOutlineSearch />} />
                            </InputGroup>

                            <Button ml={2} bg={'white'} w="50px" h={'50px'} onClick={handleOpen} >
                                <AiOutlinePlus />
                            </Button>
                        </Flex>


                        <Box my={2} >
                            <Text sx={{
                                ...boldText,
                                fontSize: '20px'
                            }} my={6} >Members
                                <Button onClick={onOpen} ml={2} borderBottom="1px solid #e3e3e3" borderRadius={0} variant={'ghost'} >Pending Members</Button>
                            </Text>

                            {
                                team?.members.filter((el: any) => el.displayName.toLowerCase().includes(searchText.toLowerCase())).map((el: any) => <MemberItem {...el} handleDeleteTeamMember={handleDeleteTeamMember} />)
                            }


                        </Box>
                    </Box>




                    <ActionSheet mouseEnable={false} ref={ref} bgStyle={{
                        background: 'rgba(0, 0, 0, 0.51)'
                    }} sheetStyle={{
                        height: '75vh',
                        background: '#F5F5F5'
                    }}>
                        <Flex justifyContent={'space-between'} flexDir={'column'} px={'25px'} py={'30px'}>

                            <Text fontWeight={600} fontSize="23px">Member Information</Text>
                            <Box mt={'40px'}>
                                <Box bg={'white'} borderRadius="8px">
                                    <Inputs
                                        isError={false}
                                        onChange={(e: any) => {
                                            setmemberCredentials({
                                                ...memberCredentials,
                                                email: e.value
                                            })
                                        }}
                                        type="email"
                                        value={email}
                                        name="email"
                                        placeholder='Email address'
                                        primaryStyles={{}}
                                    />
                                </Box>
                            </Box>

                            <Box mt={3}>
                                <Menu matchWidth  >
                                    <MenuButton bg={'white'} display={'flex'} alignItems="center" justifyContent={'space-between'} w={'100%'} sx={menuButtonStyle} as={Button} rightIcon={<BiChevronDown />}>
                                        <Text flex={0} color="#9E9E9E" fontWeight={500} >
                                            {
                                                !roles.length ? 'Roles' : roles.map((el: string) => <Text as={'span'} mx={2}>{el}</Text>)
                                            }
                                        </Text>
                                    </MenuButton>
                                    <MenuList px={'15px'}>


                                        <Box>
                                            <Radio
                                                isChecked={roles.includes('Owner')}
                                                text={'Owner'}
                                                handleClick={(e: any) => handleSelect('Owner')}
                                            />
                                        </Box>
                                        <Box>
                                            <Radio
                                                isChecked={roles.includes('Cleaner')}
                                                text={'Cleaner'}
                                                handleClick={(e: any) => handleSelect('Cleaner')}
                                            />
                                        </Box>
                                        <Box>
                                            <Radio
                                                isChecked={roles.includes('Editor')}
                                                text={'Editor'}
                                                handleClick={(e: any) => handleSelect('Editor')}
                                            />
                                        </Box>
                                        <Box>
                                            <Radio
                                                isChecked={roles.includes('Viewer')}
                                                text={'Viewer'}
                                                handleClick={(e: any) => handleSelect('Viewer')}
                                            />
                                        </Box>



                                    </MenuList>
                                </Menu>

                                <Button onClick={invitationExecute} w={'100%'} bg="#00ACAF" color={'white'} mt={4} isLoading={invitationStatus === 'pending' ? true : false} >
                                    Add Members
                                </Button>
                            </Box>
                        </Flex>
                    </ActionSheet>

                </Box>

                <Modal isOpen={isOpen} onClose={onClose} size="2xl" >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Pending Members</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>


                            {
                                teamInvitations.length ? teamInvitations.map((el: any) => (
                                    <Flex justifyContent={'space-between'} border="1px solid #e3e3e3" p={4} borderRadius="08px" mb={3} >
                                        <Box>
                                            <Text fontWeight={500} fontSize={'17px'}>{el.email}</Text>
                                            <Text>Roles: {el.roles.map((el: string) => <Text mx={1} as="span" fontWeight={600} >{el}</Text>)}</Text>
                                        </Box>
                                        <Button onClick={() => handleDeleteInvitations(el.id)}>
                                            <AiFillDelete />
                                        </Button>
                                    </Flex>

                                )) : (
                                    <Text fontWeight={600} >No Invitations</Text>
                                )
                            }

                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Layout>

    )
}


export default Team;