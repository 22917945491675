import { Box, Button, Checkbox, Flex, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { Radio } from '../../components/Cleaning/Upload';
import StepInput from './StepInput';
import TaskInput from './TaskInput';


type CleaningItemProps = {
    title: string;
    id: string;
    tasks: [
        {
            title: string;
            isImageRequired: boolean;
            id: string;
        }
    ],
    handleAction: (id: string, val: any, cb: () => void) => void;
    handleTaskUpdate: (id: any, taskId: string, val: {}) => void;
    idx: number;

    handleDelete: any

}

const CleaningItem: React.FC<CleaningItemProps> = ({ id, title, tasks, handleAction, handleTaskUpdate, idx, handleDelete }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <Box mt={7}>
            {/* Step details */}
            <Flex alignItems={'center'} justifyContent={'space-between'}>
                <Text fontWeight={500}>{idx + 1}. {title}</Text>
                <Button bg={'transparent'} border={'1px solid #2D2C31'} size={'sm'} onClick={onOpen} fontSize={'14px'} px={'13px'} py={'12px'} >
                    <AiOutlinePlus />
                    <Text ml={2}>
                        Add Task
                    </Text>
                </Button>
            </Flex>
            <Box>

                {
                    tasks.length ? (

                        <Box bg={'white'} borderRadius={'8px'} p={'16px'} mt={4} >
                            {
                                tasks.map((el, idx) => (
                                    <Flex flexDir={'column'} key={el.id} justifyContent={'space-between'} mt={'10px'} >
                                        <Flex>
                                            <Text ml={2} fontSize={'17px'} fontWeight={500}>{idx + 1}. {el.title}</Text>
                                        </Flex>
                                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                                            <Flex>
                                                <Radio
                                                    isChecked={el.isImageRequired}
                                                    text={'Require image'}
                                                    handleClick={(val: any) => {
                                                        handleTaskUpdate(id, el.id, {
                                                            ...el,
                                                            isImageRequired: !el.isImageRequired
                                                        })
                                                    }}
                                                />

                                            </Flex>

                                            <Box onClick={() => handleDelete(el.id, id)} border={'1px solid #E56F74'} padding="10px" borderRadius={'5px'} cursor={'pointer'}>
                                                <BiTrash style={{
                                                    fontSize: '16px'
                                                }} color='#E56F74' />
                                            </Box>
                                        </Flex>
                                    </Flex>

                                ))

                            }
                        </Box>
                    ) : ''

                }
                {
                    isOpen && (

                        <TaskInput
                            handleAction={(val: any) => handleAction(id, {
                                title: val,
                                isImageRequired: false,
                            }, onClose)}

                        />

                    )
                }
            </Box>



        </Box>
    )
}

export default CleaningItem;
