import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation } from 'react-router-dom'


type TabsProps = {
    options: TabItemProps[]
    onSelectTab: (val: string) => void
}

type TabItemProps = {
    text: string;
    icon: React.ReactNode;
    isActive?: boolean;
    onClickTab?: (val: string) => void

}


export const Tabs: React.FC<TabsProps> = ({ options, onSelectTab }) => {
    const lc = useLocation();

    return (
        <Flex fontFamily={'Poppins'} pos={'sticky'} bottom="0" bg={'white'} px="22px">

            {
                options.map((el) =>
                    <TabItem
                        {...el}
                        isActive={lc.pathname.split('/')[1] === el.text.toLowerCase()}
                        onClickTab={(val) => onSelectTab(val)}
                    />)
            }
        </Flex>
    )
}


const TabItem: React.FC<TabItemProps> = ({ text, icon, isActive, onClickTab }) => {

    let activeStyle = {
        pos: 'absolute',
        top: 0,
        w: '100%',
        h: '5px',
        bg: '#00acaf',
        borderRadius: `0px 0px 8px 8px`
    }


    return (
        /* @ts-ignore */
        <Flex onClick={() => onClickTab(text)} pos={'relative'} py={'21px'} fontSize={'24px'} flexDir={'column'} flex={1} alignItems="center" color={isActive ? '#00ACAF' : 'black'} cursor="pointer" >
            {
                isActive && (
                    <Box sx={activeStyle} />
                )
            }

            {icon}
            <Text mt={'5px'} fontSize={'14px'} fontWeight={400} >{text}</Text>

        </Flex>
    )
}