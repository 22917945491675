import { Box, Button, Flex, GridItem, HStack, Image, Progress, Spinner, Text } from '@chakra-ui/react';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { AiFillCloseCircle, AiOutlineClockCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { BiTask } from 'react-icons/bi';
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs';
import { db } from '../../firebase/init';
import { v4 as uuidv4 } from "uuid";

import { storageRef, uploadTaskImagee } from '../../firebase/upload';
import { getFormattedDate } from '../../Helper/date';
import photos from './photos.svg';
import { useParams } from 'react-router-dom';



const Layout = ({ children, handleReportClick, showSubmitState, onCompleteCleaning, shouldHaveControl, cleaning }) => {
  return (
    <Box>
      {children}
      {
        shouldHaveControl && (

          <Flex direction={'column'} gap='2rem'>
            {
              !showSubmitState && (

                <Button onClick={handleReportClick} maxW={'50%'} fontWeight='medium'>
                  + Add a report
                </Button>
              )
            }
            {
              cleaning.status !== 'completed' && (

                <Button fontWeight='medium' color='#fff' bg='#00ACAF' onClick={onCompleteCleaning}>
                  Complete cleaning
                </Button>
              )
            }
          </Flex>
        )
      }
    </Box>
  );
};

export default Layout;

const Profile = ({ propertyName, displayName, email, status }) => {

  return (
    <Box borderRadius={'8px'} bg='#E3EFEF' p='1.5rem' color={'#5D5F63'}>
      <Text color={'#2D2C31'} my={1} fontWeight={500} >
        Property name:{''}
        <Text as='span' ml={1} fontWeight={500} color={'#00ACAF'}>
          {propertyName}
        </Text>
      </Text>
      <Text color={'#2D2C31'} my={1} fontWeight={500} >
        Cleaner name:{' '}
        <Text as='span' ml={1} fontWeight={500} color={'#00ACAF'}>
          {displayName || 'No one Assigned or Claimed'}
        </Text>
      </Text>
      <Text color={'#2D2C31'} my={1} fontWeight={500} >
        Cleaner e-mail:{' '}
        <Text as='span' ml={1} fontWeight={500} color={'#00ACAF'}>
          {email || 'No one Assigned or Claimed'}
        </Text>
      </Text>
      <Text color={'#2D2C31'} my={1} fontWeight={500} >
        Cleaning Status:{' '}
        <Text as='span' ml={1} color={'#68BE86'}>
          {status || 'Not started'}
        </Text>
      </Text>
    </Box>
  );
};


const getPreFormattedNumber = (number) => {

  return String(number).length === 1 ? '0' + number : number
}

const TimeTrack = ({
  seconds,
  setSeconds,
  handleStart,
  handlePause,
  value,
  isTicking,
  shouldHaveControl,
  cleaning
}) => {

  let time = getFormattedDate(seconds);



  const handlePlayOrPause = () => {
    if (!isTicking) {
      handleStart()
    } else {
      handlePause()
    }
  }
  return (
    <Flex direction={'column'} align='center'>
      <Flex gap={'.2rem'} align='center' my='1.2rem'  >
        <Box><AiOutlineClockCircle fontSize={'25px'} fill="#22D3D3" /></Box>
        <Text ml={1} fontSize={'17px'} fontWeight={600}>Time Track</Text>
      </Flex>
      <Flex bg='#22D3D3' p='1.5rem' pb='2rem' w='100%' borderRadius={'8px'} justify='space-between'>
        <Box>
          <Text color={'#E3F9F9'} fontSize='2rem' fontWeight={'bold'}>
            {getPreFormattedNumber(time.hours)}
          </Text>
          <Text fontSize={'16px'} fontWeight={'500'}>Hour</Text>
        </Box>
        <Box>
          <Text color={'#E3F9F9'} fontSize='2rem' fontWeight={'bold'}>
            {getPreFormattedNumber(time.minitues)}

          </Text>
          <Text fontSize={'16px'} fontWeight={'500'}>Minutes</Text>
        </Box>
        <Box position={'relative'}>
          <Text color={'#E3F9F9'} fontSize='2rem' fontWeight={'bold'}>
            {getPreFormattedNumber(time.seconds)}

          </Text>
          <Text fontSize={'16px'} fontWeight={'500'}>Seconds</Text>
        </Box>
      </Flex>

      {
        cleaning.status !== 'completed' && shouldHaveControl && (

          <Button onClick={handlePlayOrPause} w={'100%'} mt={3} bg="#E3F0F0" height={'45px'} >
            {
              isTicking ? (
                <BsFillPauseFill fontSize={'28px'} color="#00ACAF" />
              ) : (

                <BsFillPlayFill fontSize={'28px'} color="#00ACAF" />
              )
            }
          </Button>
        )
      }

    </Flex>
  );
};



const Tasks = ({ tasks, title, validateBeforeChecked, stepKey, validationState, taskImage, cleaning, setValidationState, shouldHaveControl }) => {
  const { cleaningId } = useParams();


  const errorStyle = {
    fontSize: '14px',
    color: '#E56E73',
    fontWeight: 500,
    mt: 1
  };



  return (
    <Box my='1rem'>
      <Text fontWeight={500} fontSize="18px" textTransform={'capitalize'}>{title}</Text>

      {
        tasks.map((el) => (
          <Box>
            <Radio text={el.title} isChecked={el.isCompleted} handleClick={(e) => validateBeforeChecked(stepKey, el.id, !el.isCompleted, el.isImageRequired)} />
            {el.isImageRequired &&
              <Upload
                cleaning={cleaning}
                validationState={validationState}
                isValidationError={validationState.imageRequiredValidation[el.id]}
                cleaningId={cleaningId}
                taskImage={taskImage}
                el={el}
                taskId={el.id}
                setValidationState={setValidationState}
                shouldHaveControl={shouldHaveControl}
              />}
            {
              // @ts-ignore
              validationState.imageRequiredValidation[el.id] && (
                <Text sx={errorStyle}>
                  Please submit required image before check it
                </Text>
              )
            }
            <Flex>
              {/*               {
                el.isImageRequired && (cleaning.images.length && cleaning.images || urls).map((url) => (

                  <ImageBox file={url} cleaning={cleaning} task={el} />
                ))
              } */}


            </Flex>


          </Box>
        ))
      }
    </Box>
  );
};

const Upload = ({ isValidationError, cleaningId, taskImage, taskId, setValidationState, validationState, shouldHaveControl, cleaning }) => {
  const [taskImages, setTaskImages] = useState([]);
  const [isImageUpload, setisImageUpload] = useState(false)
  const activeStyles = {
    width: '100%',
    height: 'max-content',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '25px',
    paddingBottom: '15px',
  };

  const handleUploadImage = async (images) => {
    console.log({ images })
    setisImageUpload(true)
    let imagePromise = [];

    images.forEach((el) => {
      imagePromise.push(uploadTaskImagee(el, cleaningId));
    })
    const result = await Promise.all(imagePromise);
    let imageObjectArr = [];
    result.forEach((el) => {
      imageObjectArr.push({
        url: el,
        id: uuidv4(),
        taskId: taskId
      })
    })
    setTaskImages(imageObjectArr);
    /* Firebase Upload */
    const cleaningRef = doc(db, 'cleanings', cleaningId);
    await updateDoc(cleaningRef, {
      taskImage: arrayUnion(...imageObjectArr)
    });

    setValidationState({
      ...validationState,
      imageRequiredValidation: {
        [taskId]: false
      }
    })
    setisImageUpload(false)

  }

  const handleDeleteTaskImage = async (id) => {
    if (cleaning.status === 'completed') return
    if (!shouldHaveControl) return


    const obj = taskImages.filter((el) => el.id === id)[0];
    const ref = doc(db, 'cleanings', cleaningId);

    await updateDoc(ref, {
      taskImage: arrayRemove(obj)
    })

  }



  useEffect(() => {
    if (taskImage) {
      setTaskImages(taskImage)
    }
  }, [taskImage]);


  let img = taskImages.filter((el) => el.taskId == taskId);


  return (
    <>
      <GridItem
        px={2}
        textAlign={'center'}
        w={'100%'}
        mt='1rem'
        border={`2px dashed ${isValidationError ? '#E56E73' : ' rgba(229, 229, 229, 1)'}`}
        borderRadius='8px'
        pos={'relative'}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {

            let URLS = []
            acceptedFiles.forEach((file, idx) => {

              URLS.push(file)


            });
            setTaskImages([
              ...taskImages,
              ...URLS
            ]);

            handleUploadImage([
              ...URLS
            ])
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <Box >
                <Flex
                  sx={{
                    ...activeStyles,
                  }}
                >
                  <Image src={photos} width={'80px'} height={'80px'} alt='photo icon' />
                  <Text fontSize={'15px'} fontWeight='semibold'>
                    Drag your photos here
                  </Text>
                </Flex>

                <Box color='#313131' textAlign='center' mb='2rem'>

                </Box>
              </Box>
              <div {...getRootProps()} style={{
                paddingBottom: '30px'
              }}>
                {
                  shouldHaveControl && (
                    <input className='dropzone-input'  {...getInputProps()} multiple type={cleaning?.status === 'completed' ? 'text' : 'file'} accept='image/*' />

                  )
                }
                <Text as='u' >Upload from your device</Text>
              </div>
            </section>
          )}
        </Dropzone>

      </GridItem>
      {
        isImageUpload && <Spinner mt={3} />
      }
      <HStack mt={4}>
        {
          img.map((el) => (
            <Flex pos={'relative'}   >
              <Flex alignItems={'center'} justifyContent={'center'} pos={'absolute'} top={0} right={0} w={'20px'} height={'20px'} borderRadius={'25px'} bg="#00acaf" >
                <AiOutlineCloseCircle onClick={() => handleDeleteTaskImage(el.id)} color='black' cursor={'pointer'} />
              </Flex>
              <Image w={'60px'} h={'60px'} src={el.url} />
            </Flex>
          ))
        }
      </HStack>
    </>
  );
};

const Radio = ({ text, isChecked = false, handleClick }) => {

  const radioStyles = {
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: '4px',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
  };

  const primaryColor = '#00ACAF';

  return (
    <Flex
      align={'center'}
      fontWeight='medium'
      gap='.5rem'
      my='.6rem'
      onClick={handleClick}
    >
      <Flex border={`2px solid ${isChecked ? primaryColor : '#eee'}`} sx={{ ...radioStyles }}>
        <Box
          borderRadius='2px'
          width='85%'
          height='85%'
          p='4px'
          background={isChecked ? primaryColor : '#eee'}
        />
      </Flex>
      <Text>{text}</Text>
    </Flex>
  );
};

export {
  TimeTrack,
  Profile,
  Tasks,
  Radio
}


