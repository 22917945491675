import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const storage = getStorage();
export const storageRef = ref(storage, 'property/mountain.jpg');
export const taskRef = ref(storage, 'taskImage/');




export const uploadFile = async (file: any) => {
    // 'file' comes from the Blob or File API
    return uploadBytesResumable(storageRef, file)
}



export const uploadTaskImagee = async (file: any) => {
    let taskRef = ref(storage, `taskImage/${uuidv4()}`);
    const s = await uploadBytes(taskRef, file);
    const url = await getDownloadURL(s.ref);

    return url
}

export const uploadPropertyImages = async (file: any) => {
    let taskRef = ref(storage, `property/${uuidv4()}`);
    const s = await uploadBytes(taskRef, file);
    const url = await getDownloadURL(s.ref);

    return url
}