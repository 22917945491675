import { Box } from '@chakra-ui/react'
import { updateCurrentUser } from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../context/authContext';
import { db } from '../../firebase/init';
import useAsync from '../../hooks/useAsync';
import useForms from '../../hooks/useForms';
import CleaningForm from './CleaningForm';


const INITIAL_DATA = {
    title: '',
    steps: {

    },
    assignedProperty: {},
    assignedCleaner: {},
    scheduleAt: null,
    checkIn: '',
    checkOut: '',
    notifyOwner: false
}

const AddEditCleaning: React.FC = () => {

    const navigate = useNavigate();
    const { inputsState, handleChange } = useForms(INITIAL_DATA);
    const { currentUser } = useContext<any>(Context);

    const { assignedCleaner } = inputsState
    const handleCleaningCreate = async () => {
        try {

            const newObj = { ...inputsState };
            delete newObj.steps

            const ref = doc(collection(db, 'cleanings'));

            await setDoc(ref, {
                ...newObj,
                scheduleAt: new Date(newObj.scheduleAt).getTime(),
                id: ref.id,
                teamId: currentUser.teams[0],
                taskImage: [],
            })


            navigate('/cleaning')
        } catch (err) {
            console.log(err)
        }
    }

    const { execute, status, error } = useAsync(handleCleaningCreate, false);

    return (
        <Box px={'35px'} height={'100vh'} bg={'#F5F5F5'}>
            <CleaningForm
                isLoading={status === 'pending' ? true : false}
                formData={inputsState}
                handleAction={execute}
                handleChange={handleChange}
            />
        </Box>
    )
}

export default AddEditCleaning;


