import { Box, Button, Flex, Input } from '@chakra-ui/react';
import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';



type TaskInputProps = {
    handleAction: any
}
const TaskInput: React.FC<TaskInputProps> = ({ handleAction }) => {

    const [textTask, setTextTask] = useState('')
    return (
        <Box mt={4} bg={'white'} p={'14px'} borderRadius={'8px'} >
            <Flex>
                <Input onChange={(e) => setTextTask(e.target.value)} value={textTask} borderColor={'#E5E5E5'} placeholder='Taks name' />
                <Button onClick={() => handleAction(textTask)} w={'40px'} h={'40px'} p={'12px'} border={'1px solid gray'} ml={3} bg="transparent" >
                    <AiOutlinePlus />
                </Button>
            </Flex>
        </Box>
    )
}

export default TaskInput;