import { Box, Button, Flex } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../UI/Header';
import { GoHome } from 'react-icons/go';
import { GiVacuumCleaner } from 'react-icons/gi';
import { GrAnalytics } from 'react-icons/gr';
import { RiTeamLine } from 'react-icons/ri';
import { Tabs } from '../UI/Tabs';
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../../context/authContext';

const tabsOptions = [
    {
        text: 'Property',
        icon: <GoHome />,
    },
    {
        text: 'Cleaning',
        icon: <GiVacuumCleaner />,
    },
    {
        text: 'Report',
        icon: <GrAnalytics />,
    },
    {
        text: 'Team',
        icon: <RiTeamLine />,
    }
]

export const Layout: React.FC<{
    children: React.ReactNode
}> = ({ children }) => {

    const { currentUser } = useContext<any>(Context)
    const navigate = useNavigate();
    const lc = useLocation()
    const onAddIconClick = () => navigate('add');

    const onTabClick = (val: string) => {



        if (val === 'Team') {
            navigate(`/team/${currentUser.teams[0]}`)
            return
        }
        navigate(`/${val.toLowerCase()}`)
    }

    console.log(lc)

    return (
        <>
            <Flex flexDir={'column'} height={'100vh'} overflowY={'scroll'} px={'30px'} bg={'#F7F7F7'} pos="relative"  >
                <Header />
                <Box flex={1} pos="relative" pb={'120px'} >
                    {children}
                    {
                        !lc.pathname.includes('team') && (
                            <Button onClick={onAddIconClick} borderRadius={'100%'} boxShadow={'0 10px 20px 1px rgb(0 161 164 / 35%)'} color={'white'} bg={'#00A1A3'} pos={'sticky'} left={'100vw'} bottom={'120px'} w="60px" h={'60px'}  >
                                <AiOutlinePlus style={{
                                    fontSize: '24px'
                                }} />
                            </Button>

                        )
                    }

                </Box>
            </Flex>
            <Tabs onSelectTab={onTabClick} options={tabsOptions} />

        </>
    )
}
