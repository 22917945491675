import { useState } from "react";


const useForms = (initialValue: any) => {
    const [inputsState, setInputsState] = useState(initialValue);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setInputsState({
            ...inputsState,
            [name]: value
        })
    }



    const cb = (obj: {}) => {
        setInputsState({
            ...inputsState,
            ...obj
        })
    }


    return { inputsState, handleChange, cb }

}

export default useForms;