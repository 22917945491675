


export const getFormattedDate = (totalSeconds: number) => {
    let hours = Number(String(totalSeconds / 3600).split('.')[0]);
    let seconds =  Number(String(totalSeconds - ( hours * 3600)).split('.')[0])
    let minitues =  Number(String(seconds / 60).split('.')[0]);
    seconds =  totalSeconds -  (minitues * 60)
    
    return {
        hours,
        seconds,
        minitues
    }
}