import React from 'react';
import {
    Routes as Router,
    Route,
} from "react-router-dom";
import Cleanings from '../../views/Cleanings';
import AddEditCleaning from '../../views/Cleanings/AddEditCleaning';
import CleaningDetails from '../../views/Cleanings/CleaningDetails';
import Login from '../../views/Login/Login';
import Notifications from '../../views/Notifications/Notifications';
import Properties from '../../views/Properties';
import AddEditProperties from '../../views/Properties/AddEditProperties';
import PropertyDetails from '../../views/Properties/PropertyDetails';
import Reports from '../../views/Reports';
import Team from '../../views/Team/Team';
import Signup from './../../views/signup/signup';
import ProtectedRoute from './ProtectedRoute';

const Routes: React.FC = () => {
    return (
        <Router>
            <Route path='signup' element={
                <Signup />
            } />
            <Route path='login' element={
                <Login />
            } />
            <Route path='notifications' element={
                <ProtectedRoute>
                    <Notifications />
                </ProtectedRoute>

            } />
            {/* Properties */}
            <Route path='property' element={
                <ProtectedRoute>
                    <Properties />
                </ProtectedRoute>

            } />
            <Route path='property/details/:id' element={
                <ProtectedRoute>
                    <PropertyDetails />
                </ProtectedRoute>

            } />
            <Route path='property/:actionType' element={
                <ProtectedRoute>
                    <AddEditProperties />
                </ProtectedRoute>

            } />
            <Route path='property/:actionType/:propertyId' element={

                <ProtectedRoute>
                    <AddEditProperties />
                </ProtectedRoute>
            } />

            {/* Cleanings */}
            <Route path='cleaning/:actionType' element={
                <ProtectedRoute>
                    <AddEditCleaning />
                </ProtectedRoute>

            } />
            <Route path='cleaning' element={
                <ProtectedRoute>
                    <Cleanings />
                </ProtectedRoute>
            } />

            <Route path='cleaning/details/:cleaningId' element={
                <ProtectedRoute>
                    <CleaningDetails />
                </ProtectedRoute>
            } />
            <Route path='report' element={
                <ProtectedRoute>
                    <Reports />
                </ProtectedRoute>

            } />

            <Route path='team/:teamId' element={
                <ProtectedRoute>
                    <Team />
                </ProtectedRoute>

            } />
            {/*
            <Route path='cleanings/:actionType/:cleanerId' element={
                <ProtectedRoute>
                    <AddEditCleaning />
                </ProtectedRoute>
            } />


 */}

            {/* Team */}


        </Router>
    )
}


export default Routes


