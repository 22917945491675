import { Box, Button, Text } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../firebase/init';
import useAsync from '../../hooks/useAsync';

const PropertyDetails: React.FC = () => {
    const [property, setProperty] = useState<any>({});
    const { id } = useParams<any>();
    const navigate = useNavigate();
    useEffect(() => {

        (async () => {
            // @ts-ignore
            let ref = doc(db, 'properties', id);
            const snapshot = await getDoc(ref);
            setProperty(snapshot.data());
        })()
    }, []);


    const createCleaning = async () => {
        const { currentUser } = getAuth()
        let cleaningObj = {
            assignedProperty: {
                createdBy: property.createdBy,
                id: property.id,
                propertyDescription: property.propertyDescription,
                propertyName: property.propertyName,
                steps: property.steps
            },
            assignedCleaner: {
                uid: currentUser?.uid,
                email: currentUser?.email,
                displayName: currentUser?.displayName,
                avatar: ''
            },
            scheduleAt: new Date().getTime()
        }

        let scheduleAt = String(new Date().getTime())
        const ref = doc(db, 'cleanings', property.id, 'dates', scheduleAt);

        try {
            await setDoc(ref, {
                ...cleaningObj
            })

            navigate(`/cleanings/property/${property.id}/${scheduleAt}`)

        } catch (err) {
            console.log(err)
        }

    }

    const { status, execute } = useAsync(createCleaning, false);

    return (
        <Box px={'40px'} fontFamily="Poppins">
            Property Details
            <Text>Property Title: {property.propertyName}</Text>
            <Text>Property Description: {property.propertyDescription}</Text>
            <Text>Created By Id: {property.createdBy}</Text>

            <Button mt={3} isLoading={status === 'pending'} onClick={execute}>Create A cleaning for this property</Button>
        </Box>
    )
}

export default PropertyDetails;