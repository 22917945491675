import { arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore"
import { db } from "./init"
import { sentInviteMail } from "./mail";





export const getTeam = async (teamId: any) => {
    try {
        const ref = doc(db, 'teams', teamId);
        const snapshot = await getDoc(ref);
        return snapshot.data();

    } catch (err) {
        console.log(err)
    }
}
export const createTeam = async (obj: any) => {


    try {
        const ref = doc(collection(db, 'teams'));
        let teamObject = {
            createdBy: obj.createdBy,
            members: [{
                // Roles, email, displayName
                ...obj.member
            }],
            name: 'Demo name',
            createdAt: new Date().getTime(),
            teamId: ref.id,
        }
        await setDoc(ref, teamObject);
        return ref.id;
    } catch (err) {
        console.log(err)
    }


}


export const sentInvitation = async (invitationObject: any, cb: any) => {
    try {
        const ref = doc(collection(db, 'invitations'));
        let url = window.location.origin
        await setDoc(ref, {
            ...invitationObject,
            id: ref.id,
            invitationUrl: `${url}/signup?invitationid=${ref.id}`
        })
        cb({
            ...invitationObject,
            id: ref.id
        })
        sentInviteMail(invitationObject.email, {
            invitationId: ref.id
        })

    } catch (err) {
        console.log(err)
    }

}


export const getInvitation = async (id: any) => {
    const ref = doc(db, 'invitations', id);
    const snapshot = await getDoc(ref);

    return snapshot.data()

}

export const addMemberToTeam = async (obj: any) => {
    const ref = doc(db, 'teams', obj.teamId);
    try {

        await updateDoc(ref, {
            members: arrayUnion({
                roles: obj.roles,
                email: obj.email,
                displayName: obj.displayName,
                uid: obj.uid,
                avatar: ''
            })
        })
    } catch (err) {
        console.log({ err })
    }

}


export const getTeamInvitations = async (teamId: string) => {
    const q = query(collection(db, 'invitations',), where('teamId', '==', teamId));
    const snapshot = await getDocs(q);

    let data: any = [];

    snapshot.docs.forEach((el) => {
        data.push(el.data())
    })

    return data;
}

export const updateInvitationsStatus = async (id: string) => {
    const ref = doc(db, 'invitations', id);
    await updateDoc(ref, {
        status: 'accepted'
    })

}


