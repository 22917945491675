
import React, { useCallback, useEffect, useState } from 'react';
const useAsync = (asyncFunction: () => Promise< any>, immediate = true) => {

    const [shouldrun, setShouldrun] = useState(false)
    const [status, setStatus] = useState("idle");
    const [value, setValue] = useState<any>(null);
    const [error, setError] = useState(null);
    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => {
      setStatus("pending");
      setValue(null);
      setError(null);
      return asyncFunction()
        .then((response) => {
          setValue(response);
          setStatus("success");
          setShouldrun(true)

        })
        .catch((error) => {
          setError(error);
          setStatus("error");
          setShouldrun(true)

        });
    }, [asyncFunction]);
    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
      if (immediate && !shouldrun) {
        console.log('get callled')
        execute();
      }

    }, [execute, immediate]);
    return { execute, status, value, error, setValue };
  };

/* const useAsync = (cb:  () =>  Promise<void>) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorState, setErrorState] = useState<any>({
        message: '',
        isError: false
    });
    const [isSucceed, setIsSucceed] = useState(false);

    const handleAsync = async () => {
        setIsLoading(true);
        try {
            await cb();
            setIsSucceed(true)
        } catch (err) {
            setErrorState({
                message: err,
                isError: true
            });

        }
    }


    return { isLoading, errorState, isSucceed, handleAsync };
}
 */
export default useAsync;