import { getAuth } from "firebase/auth";
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { auth, db } from "./init";



export const createProperty = async (propertyObject: any) => {
  const db = getFirestore()
  const ref = doc(collection(db, 'properties'));
  const { currentUser } = auth;
  await setDoc(ref, {
    ...propertyObject,
    id: ref.id,
    createdBy: currentUser?.uid
  })

}


export const getAllProperties = async (teamId: string) => {
  const ref = query(collection(db, 'properties'), where('teamId', '==', teamId));
  const snapshot = await getDocs(ref);
  let data: any = [];
  snapshot.forEach((el) => data.push(el.data()))


  return data;

}