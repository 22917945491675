import { Box, Button, Flex, Heading, Image, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getAllProperties } from '../../firebase/Properties';
import useAsync from '../../hooks/useAsync';
import PropertiesItem from './PropertiesItem';
import { BsSearch, } from 'react-icons/bs';
import { BiFilter } from 'react-icons/bi';
import logo from './../../Assets/Images/logo.jpg'
import { Context } from '../../context/authContext';
import Header from '../../components/UI/Header';
import { Layout } from '../../components/Layout/Layout';



const Properties: React.FC = () => {
    const { currentUser } = useContext<any>(Context)
    const [property, setProperty] = useState<any>(null);
    const [searchText, setSearchText] = useState('');
    const [filteredProperty, setFilteredProperty] = useState([])
    const handleSearch = () => {
        const filteredProperty = property.filter((el: any) => el.propertyName.toLowerCase().includes(searchText.toLowerCase()));
        setFilteredProperty(filteredProperty);

    }

    useEffect(() => {
        if (!property) return;
        setFilteredProperty(property)

    }, [property])



    useEffect(() => {

        if (!currentUser) return;
        console.log({ currentUser }, 'before reload')

        if (currentUser && !currentUser.teams) {
            window.location.reload();
            return
        };
        console.log({ currentUser }, 'before property Fetch');

        (async () => {
            const property = await getAllProperties(currentUser.teams[0]);
            setProperty(property)

        })();


    }, [currentUser])
    console.log({ currentUser }, 'run time')
    return (
        <Layout>

            <Box bg={'#F7F7F7'} height={'100vh'} fontFamily={'Poppins'}>
                <Box>

                    <Box bg={'white'} mt={'15px'}>
                        <Flex alignItems={'center'} py={'10px'}>
                            <InputGroup _focus={{
                                outline: 0,
                                border: 0
                            }} >
                                <Input value={searchText} onChange={(e) => {
                                    if (!e.target.value && property) {
                                        setFilteredProperty(property)
                                    }
                                    setSearchText(e.target.value)
                                }} focusBorderColor='none' placeholder='What are you looking for?' border={'none'} />
                                <InputRightElement children={
                                    <BsSearch onClick={handleSearch} cursor="pointer" />
                                } />

                            </InputGroup>

                            <Button fontSize={'25px'} variant={'ghosts'}>
                                <BiFilter />
                            </Button>
                        </Flex>
                    </Box>
                </Box>
                <Box pt={1}>
                    <Box>
                        <Text fontWeight={500} mt={2} mb={4} fontSize={'18px'}>All Properties</Text>
                    </Box>
                    {
                        filteredProperty.length ? filteredProperty?.map((el: any) => <PropertiesItem {...el} />
                        ) : (
                            <Text textAlign={'center'} my="10px" fontWeight={600} color="gray.500" >No Properties </Text>
                        )
                    }
                </Box>
            </Box>

        </Layout>

    )
}

export default Properties;