import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Flex, Text } from '@chakra-ui/react'
import { AiOutlineClockCircle } from 'react-icons/ai';
import { formatDate } from '.';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/init';
import { useAuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';

interface CleaningPreviewProps {
    cleanings: any[]
}


const getTodayOrTommorow = (date: string) => {

    if (date === formatDate(new Date())) {
        return 'Today'
    } else if (formatDate(new Date().setDate(new Date().getDate() + 1)) === date) {
        return 'Tommorow'
    } else {
        return date
    }

}

function isFilteredOut(filters: any, cleaning: any, currentUser: any) {

    if (Object.keys(filters.selectedCleaner).length && !(filters.selectedCleaner.uid === cleaning.assignedCleaner.uid)) {
        return true;
    }

    if (Object.keys(filters.selectedProperty).length && !(filters.selectedProperty.id === cleaning.assignedProperty.id)) {
        return true;
    }
    const assignedToLength = Object.keys(cleaning.assignedCleaner).length

    if (filters.status &&
        (
            (filters.status === 'assigned' && !assignedToLength) ||
            (filters.status === 'unassigned' && assignedToLength)
        )
    ) {
        return true;
    }
    /*  if (filters.status &&
         (
             (filters.status === 'assigned' && !date.assignedTo.length) ||
             (filters.status === 'unassigned' && date.assignedTo.length)
         )
     ) {
         return true;
     }
     if (
         (filters.selectedSegment === 'myCleanings' && !date.assignedTo.includes(currentUser.id)) ||
         (filters.selectedSegment === 'availableCleanings' && date.assignedTo.includes(currentUser.id))
     ) {
         return true;
     } */
    return false;
}

const CleaningPreview: React.FC<any> = ({ cleaning, date, filters, currentCleaner }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuthContext();
    let cleaningItemStyle = {
        borderRadius: '8px',
        border: '1px solid #00ACAF',
        px: '16px',
        py: '13px',
        my: '14px',
        bg: '#D1EBEB',
    }

    let boldText = {
        fontSize: '18px',
        fontWeight: '600'
    }

    const claimCleaning = async (id: string) => {
        const ref = doc(db, 'cleanings', id);

        await updateDoc(ref, {
            assignedCleaner: currentUser
        })


        navigate(`details/${id}`)

    }


    let buttonStyle = {
        fontStyle: '14px',
        background: '#12b2b6',
        color: 'white',
        borderRadius: '20px'
    }
    return (
        <Box>

            <Accordion defaultIndex={[0]} allowToggle mt={'20px'}>
                <AccordionItem border={0}>
                    <h2>
                        <AccordionButton pl={0} outline={0}>
                            <Flex alignItems={'center'} flex='1' textAlign='left'>
                                <Text fontSize={'16px'} color={'#00ACAF'} fontWeight={'medium'}>
                                    {getTodayOrTommorow(date)}
                                </Text>
                                <Flex ml={2}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    w={'35px'} height={'35px'}
                                    borderRadius={'3px'}
                                    bg={'rgba(58, 188, 188, 0.2)'}
                                    color={'#00ACAF'}>
                                    {cleaning.length}
                                </Flex>
                            </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pl={0} pb={4}>

                        {
                            cleaning.map((clr: any) => {

                                return (
                                    <>

                                        {
                                            isFilteredOut(filters, clr, {}) ? (
                                                <Text>No assigned Cleaning</Text>
                                            ) : (
                                                <Flex justifyContent={'space-between'} sx={cleaningItemStyle}>
                                                    <Box>
                                                        <Text sx={boldText}>{clr.title}</Text>
                                                        <Text mt={'5px'} mb={'5px'}>
                                                            Property name: <Text sx={boldText} fontSize={'16px'} as={'span'}>
                                                                {clr.assignedProperty.propertyName}
                                                            </Text>
                                                        </Text>
                                                        <Text mb={'5px'}>
                                                            Access Code: <Text sx={boldText} fontSize={'16px'} as={'span'}>
                                                                {clr.assignedProperty.accessCode || 'No access Code'}
                                                            </Text>
                                                        </Text>
                                                        <Text mb={'5px'}>
                                                            Schedule at: <Text sx={boldText} fontSize={'16px'} as={'span'}>{formatDate(new Date(clr.scheduleAt))}</Text>
                                                        </Text>
                                                        {
                                                            !Object.keys(clr.assignedCleaner).length && currentCleaner?.roles?.includes('Cleaner') && (
                                                                <Button sx={buttonStyle} onClick={() => claimCleaning(clr.id)} fontSize={'15px'} my={2}>Claim This Cleaning</Button>
                                                            )
                                                        }
                                                        {
                                                            !currentCleaner?.roles?.includes('Cleaner') ? (
                                                                <Button sx={buttonStyle} onClick={() => navigate(`details/${clr.id}`)} fontSize={'15px'} my={2}>See Details</Button>
                                                            ) : ''
                                                        }
                                                        {
                                                            Object.keys(clr.assignedCleaner).length ? (
                                                                <>
                                                                    <Divider borderColor={'gray'} mt={4}></Divider>
                                                                    <Text mt={3} mb={2} fontWeight={600}>This cleaning is claimed/assigned to:</Text>
                                                                    <Flex alignItems={'center'}>
                                                                        <Text>Cleaner Name: </Text> <Text ml={1} sx={boldText} fontSize={'15px'} as={'span'}>
                                                                            {clr.assignedCleaner.displayName}
                                                                        </Text>
                                                                    </Flex>
                                                                </>
                                                            ) : ''
                                                        }


                                                    </Box>
                                                    <Flex
                                                        onClick={() => navigate(`details/${clr.id}`)}
                                                        cursor={'pointer'}
                                                        alignItems={'center'}
                                                        justifyContent={'center'}
                                                        border={'1px solid #F5F5F5'}
                                                        borderRadius={'4px'} bg={'white'}
                                                        w={'36px'}
                                                        h={'36px'}
                                                        fontSize={'20px'}>
                                                        <AiOutlineClockCircle style={{
                                                            fill: '#00ACAF'
                                                        }} />
                                                    </Flex>

                                                </Flex>
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                        {/*                {
                         isFilteredOut(filters)   ? (
                                <Text color={'#7d7d7d'}>No Cleaning Available</Text>
                            ) : cleaning.map(() => (
                                <Flex justifyContent={'space-between'} sx={cleaningItemStyle}>
                                    <Box>
                                        <Text sx={boldText}>Geraldine Thomas</Text>
                                        <Text mt={'5px'} mb={'5px'}>
                                            Property name: <Text sx={boldText} fontSize={'16px'} as={'span'}>Demo</Text>
                                        </Text>
                                        <Text mb={'5px'}>
                                            Access Code: <Text sx={boldText} fontSize={'16px'} as={'span'}>12345</Text>
                                        </Text>
                                        <Text mb={'5px'}>
                                            Schedule at: <Text sx={boldText} fontSize={'16px'} as={'span'}>18 July 2022; 8:00 AM</Text>
                                        </Text>

                                    </Box>
                                    <Flex alignItems={'center'} justifyContent={'center'} border={'1px solid #F5F5F5'} borderRadius={'4px'} bg={'white'} w={'36px'} h={'36px'} fontSize={'20px'}>
                                        <AiOutlineClockCircle style={{
                                            fill: '#00ACAF'
                                        }} />
                                    </Flex>
                                </Flex>
                            ))
                        } */}
                    </AccordionPanel>
                </AccordionItem>

            </Accordion>
        </Box >
    )
}


export default CleaningPreview