
import { useContext, useEffect, useState } from "react";

export const useLocationSuggestions = (text: string) => {
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);



    const getLocation = async () => {
        try {
            const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?types=country,address,place&access_token=pk.eyJ1IjoiaGltZWwxMjYiLCJhIjoiY2wxZ2FoeHM4MDd2OTNyb3JlcHZub3R4biJ9.iXUC5niBfA83FT2MYlWvpg&autocomplete=true&country=US`;

            const res = await fetch(endpoint);
            const data = await res.json();

            if (data.features.length) {
                setSuggestions(data.features);
                setShow(true);
            }

        } catch (error) {
            console.log('Error fetching data, ', error);
        }
    }



    return {
        suggestions,
        setSuggestions,
        setError,
        error,
        show,
        setShow,
        getLocation
    };
};
