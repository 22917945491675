import { Box, Button, Flex, Image, Spinner, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BiArrowBack, BiChevronLeft, BiFilter } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import SegmentedControl from '../../components/Segment/Segment';
import { getAllCleaners, getAllCleanings, getTeamAllCleaners } from '../../firebase/Cleanings';
import useAsync from '../../hooks/useAsync';
import logo from './../../Assets/Images/logo.jpg'
import ActionSheet from "actionsheet-react";
import CleaningFilter from './CleaningFilter';
import { getAllProperties } from '../../firebase/Properties';
import CleaningPreview from './CleaningPreview';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/init';
import { Context } from '../../context/authContext';
import { Layout } from '../../components/Layout/Layout';

export function formatDate(date: any) {
    let mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    let d = new Date(date),
        month = mS[(d.getMonth())],
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join(' ');
}


export const getFilteredCleanings = (cleanings: any, dateRange: any) => {
    if (!cleanings) return {}
    const tempFilteredSchedule: any = {};
    let date = new Date(dateRange.startDate); // date object
    tempFilteredSchedule[formatDate(new Date(date))] = {
        cleaning: []
    };
    // formate the date and setting the object with that formatted date value;


    // Looping will continue until the current date is greater then 
    while (date < dateRange.endDate) {
        date.setDate(date.getDate() + 1);
        // increasing date value equavelnt to i++
        tempFilteredSchedule[formatDate(new Date(date))] = { cleaning: [] };

    }

    cleanings.forEach((cleaning: any) => {
        const endDate = new Date(cleaning.scheduleAt);

        if (tempFilteredSchedule[formatDate(endDate)]) {
            tempFilteredSchedule[formatDate(endDate)].cleaning.push(cleaning);
        }


    })


    return tempFilteredSchedule
}


const Cleanings: React.FC = () => {
    const currentDate = new Date();
    const oneWeekFromNow = new Date((new Date()).setDate(currentDate.getDate() + 6));
    const [currentCleaner, setCurrentCleaner] = useState<any>({})
    const { currentUser } = useContext<any>(Context);
    const [properties, setproperties] = useState<any>([]);
    const [cleaners, setCleaners] = useState<any>([]);
    const [cleanings, setcleanings] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [filteredState, setFilteredState] = useState({
        range: [
            {
                startDate: new Date(),
                endDate: oneWeekFromNow,
                key: 'selection'
            }
        ],
        filteredCleanings: [],
        selectedCleaner: {},
        selectedProperty: {},
        selectedSegment: '',
        status: ''

    });

    const { filteredCleanings } = filteredState

    const ref = useRef();


    const navigate = useNavigate();


    const handleOpen = () => {
        // @ts-ignore
        ref.current.open();
    };

    const handleClose = () => {
        // @ts-ignore
        ref.current.close();
    };

    const handleReset = () => {
        setFilteredState({
            range: [
                {
                    startDate: new Date(),
                    // @ts-ignore
                    endDate: null,
                    key: 'selection'
                }
            ],
            filteredCleanings: [],
            selectedCleaner: {},
            selectedProperty: {},
            selectedSegment: '',
            status: ''

        })
    }

    const handleFilterSubmit = () => {
        const filteredVal: any = getFilteredCleanings(cleanings, filteredState.range[0])
        setFilteredState({
            ...filteredState,
            filteredCleanings: filteredVal,


        })

    }

    useEffect(() => {

        const ref = collection(db, "cleanings");
        onSnapshot(ref, (querySnapshot) => {

            const firestoreCleaings: any = [];
            querySnapshot.forEach((doc) => {
                firestoreCleaings.push(doc.data());
            });
            const filteredVal: any = getFilteredCleanings(firestoreCleaings, filteredState.range[0]);
            setFilteredState({
                ...filteredState,
                filteredCleanings: filteredVal,
            })
            setcleanings(firestoreCleaings);
        });



    }, [])

    useEffect(() => {
        if (!currentUser) return;
        (async () => {
            const properties = await getAllProperties(currentUser.teams[0]);
            const cleaners = await getTeamAllCleaners(currentUser.teams[0]);
            setCurrentCleaner(cleaners.filter((el: any) => el.uid === currentUser.uid)[0])
            setproperties(properties);
            setCleaners(cleaners)
            setIsLoading(false)
        })()
    }, [currentUser])

    return (
        <Layout>


            <Box mt={'10px'} mb={'16px'}>
                <SegmentedControl
                    name="group-1"
                    callback={(val: any) => setFilteredState({ ...filteredState, selectedSegment: val })}
                    controlRef={useRef()}
                    segments={[
                        {
                            label: "All",
                            value: "all",
                            ref: useRef()
                        },
                        {
                            label: "My",
                            value: "my",
                            ref: useRef()
                        },
                        {
                            label: "Available",
                            value: "available",
                            ref: useRef()
                        }
                    ]}
                />

            </Box>
            <Flex alignItems={'center'} justifyContent={'flex-end'}>
                <Button onClick={handleOpen} bg={'white'} w={'50px'} height={'50px'} >
                    <BiFilter fontSize={'55px'} />
                </Button>
            </Flex>

            {
                currentUser && !isLoading ? (
                    <>


                        <Box>
                            <Text fontSize={'15px'} color={'#9C9C9C'} fontWeight={500}>Today</Text>
                            <Text fontSize={'22px'} fontWeight={500}>{formatDate(new Date())}</Text>
                        </Box>

                        {
                            // @ts-ignore
                            Object.keys(filteredCleanings).map((key: any) => <CleaningPreview currentCleaner={currentCleaner} filters={filteredState} {...filteredCleanings[key]} date={key} />)
                        }


                        <ActionSheet mouseEnable={false} ref={ref} bgStyle={{
                            background: 'rgba(0, 0, 0, 0.51)'
                        }} sheetStyle={{
                            height: '75vh',
                            background: '#F5F5F5'
                        }}>
                            <Flex justifyContent={'space-between'} flexDir={'column'} px={'25px'} py={'30px'}>
                                <Flex alignItems={'center'} justifyContent={'space-between'}>
                                    <BiChevronLeft fontSize={'30px'} />
                                    <Text fontSize={'20px'} fontWeight={500}>Filter</Text>
                                    <Button onClick={handleReset} variant={'ghost'} color={'#7D7D7D'}>Reset</Button>
                                </Flex>

                                <Box mt={'40px'}>
                                    <CleaningFilter
                                        properties={properties}
                                        filterState={filteredState}
                                        cleaners={cleaners}
                                        handleFilterState={setFilteredState}
                                        handleFilterSubmit={handleFilterSubmit}
                                    />
                                </Box>
                            </Flex>
                        </ActionSheet>
                    </>


                ) : (
                    <Spinner />
                )
            }


        </Layout>

    )
}

export default Cleanings;
