import { collection, doc, setDoc } from "firebase/firestore"
import { db } from "./init"



export const createNotification = async (notificationObject: any) => {

    const ref = doc(collection(db, 'notifications'));
    await setDoc(ref, {
        ...notificationObject,
        id: ref.id
    })

}