import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Flex, Menu, MenuButton, MenuItem, MenuList, Radio, Text, useDisclosure } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { addEditType } from '../../Types/commonType';
import { collection, doc, DocumentData, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';

import { Calendar } from 'react-date-range';
import Header from '../../components/UI/Header';
import Inputs from '../../components/UI/Inputs';
import { BiChevronDown } from 'react-icons/bi';
import { formatDate } from '.';
import { Context } from '../../context/authContext';



export const CleaningForm: React.FC<addEditType> =
    ({ formData, isLoading, handleAction, handleChange, }) => {
        const [cleaners, setCleaners] = useState<any>();
        const [properties, setProperties] = useState<any>();
        const { isOpen, onOpen, onClose } = useDisclosure();
        const { currentUser } = useContext<any>(Context)
        const { title, steps, assignedProperty, assignedCleaner, scheduleAt, notifyOwner } = formData;

        const handleStepsAdd = (val: string) => {
            const id = uuidv4();
            const newSteps = { ...steps };
            newSteps[id] = {
                id,
                title: val,
                tasks: []
            }

            handleChange({
                /* @ts-ignore */
                target: {
                    name: 'steps',
                    value: newSteps
                }
            })
            onClose()
        }

        const handleTaskAdd = (id: string, taskVal: {}, cb: () => void) => {
            const newSteps = { ...steps };
            const currentStep = newSteps[id];

            currentStep.tasks = [
                ...currentStep.tasks,
                // @ts-ignore
                { ...taskVal, isCompleted: false, id: uuidv4() }
            ]
            newSteps[id] = currentStep;
            handleChange({
                /* @ts-ignore */
                target: {
                    name: 'steps',
                    value: newSteps
                }
            })

            cb()

        }


        const handleTaskUpdate = (id: string, taskId: string, value: any) => {
            const newSteps = { ...steps };
            const currentStep = newSteps[id];
            const tasks = currentStep.tasks;

            const itemWillRemovedIdx = tasks.findIndex((el: any) => el.id === taskId);

            tasks.splice(itemWillRemovedIdx, 1, value)
            handleChange({
                /* @ts-ignore */
                target: {
                    name: 'steps',
                    value: newSteps
                }
            })
        }


        const getCleanersAndProperties = async () => {
            const db = getFirestore()
            const propertiesRef = collection(db, 'properties');
            const teamRef = doc(db, 'teams', currentUser.teams[0])
            const querysProperties = query(propertiesRef, where('teamId', '==', currentUser.teams[0]))
            const [teamSnapshot, propertiesSnapshot] = await Promise.all([getDoc(teamRef), getDocs(querysProperties)]);
            const teamData: any = teamSnapshot.data();
            let cleaners: DocumentData[] = [];
            let properties: DocumentData[] = [];

            teamData.members.forEach((el: any) => {
                console.log({ el })
                if (el.roles.includes('Cleaner')) {
                    cleaners.push(el)
                }
            })
            propertiesSnapshot.forEach((el) => {
                properties.push(el.data())
            })

            setCleaners(cleaners);
            setProperties(properties);



        }


        useEffect(() => {
            if (!currentUser) return;

            getCleanersAndProperties()
        }, [currentUser]);


        const propertySelect = (property: any) => {
            const selectedProperty = properties.filter((el: any) => el.propertyName === property.propertyName)[0];
            let newObj = {
                id: selectedProperty.id,
                createdBy: selectedProperty.createdBy,
                propertyDescription: selectedProperty.propertyDescription,
                propertyName: selectedProperty.propertyName,
                steps: selectedProperty.steps,
                ownerInfo: selectedProperty.ownerInfo
            }
            handleChange({
                // @ts-ignore
                target: {
                    name: 'assignedProperty',
                    // @ts-ignore
                    value: newObj
                }
            })

        }

        const cleanersSelect = (cleaner: any) => {
            const selectedCleaner = cleaners.filter((el: any) => el.uid === cleaner.uid)[0];
            let newObj = {
                uid: selectedCleaner.uid,
                displayName: selectedCleaner.displayName,
                email: selectedCleaner.email,
                avatar: selectedCleaner.avatar
            }
            handleChange({
                // @ts-ignore
                target: {
                    name: 'assignedCleaner',
                    // @ts-ignore
                    value: newObj
                }
            })

        }

        const menuButtonStyle = {
            bg: 'white',
            border: '1px solid #E5E5E5',
            borderRadius: '6px',
            height: '47px',
        }

        const menuButtonText = {
            fontSize: '15px',
            color: '#9E9E9E',
            textAlign: 'left'
        }

        const menuItemHeading = {
            fontSize: '15px',
            color: '#2D2C31',
            fontWeight: '500'
        }
        const menuSubHeading = {
            fontSize: '14px',
            color: '#2D2C31',
            fontWeight: '300'
        }




        return (
            <Box py={1} fontFamily={'Poppins'}>
                <Header />
                <Text fontWeight={600} fontSize={'22px'} >Add cleaning</Text>
                <Box mb={'16px'} mt={'25px'} bg={'white'} borderRadius={'8px'}>
                    <Inputs
                        isError={false}
                        onChange={(e: any) => {
                            handleChange({
                                /* @ts-ignore */
                                target: {
                                    name: 'title',
                                    value: e.value
                                }
                            })
                        }}
                        value={title}
                        name="title"
                        placeholder='Cleaning title'
                        primaryStyles={{}}
                    />
                </Box>


                <Box>
                    <Menu >
                        <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                            <Text sx={menuButtonText}>
                                {
                                    scheduleAt ? formatDate(scheduleAt) : 'Select date'
                                }
                            </Text>
                        </MenuButton>
                        <MenuList >
                            <Calendar date={scheduleAt} onChange={(date) => handleChange({
                                // @ts-ignore
                                target: {
                                    name: 'scheduleAt',
                                    // @ts-ignore
                                    value: date
                                }
                            })
                            } />
                        </MenuList>
                    </Menu>
                </Box>
                <Box>
                    <Menu matchWidth>
                        <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                            <Text sx={menuButtonText}>
                                {
                                    !assignedCleaner.uid ? 'All Team members' : assignedCleaner.displayName
                                }
                            </Text>
                        </MenuButton>
                        <MenuList >
                            {
                                cleaners?.map((el: any) => (
                                    <MenuItem onClick={() => cleanersSelect(el)}  >
                                        <Box>
                                            <Text sx={menuItemHeading}>{el.displayName}</Text>
                                            <Text mt={1} sx={menuSubHeading}>{el.email}</Text>
                                        </Box>
                                    </MenuItem>
                                ))
                            }
                        </MenuList>
                    </Menu>
                </Box>
                <Box >
                    <Menu matchWidth >
                        <MenuButton sx={menuButtonStyle} w={'100%'} mb={3} as={Button} rightIcon={<BiChevronDown />} >
                            <Text sx={menuButtonText}>
                                {
                                    !assignedProperty.steps ? 'All Properties' : assignedProperty.propertyName
                                }
                            </Text>
                        </MenuButton>
                        <MenuList >
                            {
                                properties?.map((el: any) => (
                                    <MenuItem onClick={() => propertySelect(el)}  >
                                        <Box>
                                            <Text sx={menuItemHeading}>{el.propertyName}</Text>
                                            <Text mt={1} sx={menuSubHeading}>{el.propertyDescription}</Text>
                                        </Box>
                                    </MenuItem>
                                ))
                            }
                        </MenuList>
                    </Menu>

                    <Flex>
                        <Checkbox isChecked={notifyOwner} onChange={(e) =>
                            handleChange({
                                /* @ts-ignore */
                                target: {
                                    name: 'notifyOwner',
                                    /* @ts-ignore */

                                    value: e.target.checked
                                }
                            })
                        } />
                        <Text ml={2} fontWeight={500}>Notify to your owner. </Text>
                    </Flex>
                </Box>


                <Button fontWeight={500} bg={'#00ACAF'} color={'white'} height={'45px'} mt={3} w={'100%'} isLoading={isLoading} onClick={handleAction} >
                    Create Cleaning
                </Button>


            </Box >
        )
    }

export default CleaningForm;
