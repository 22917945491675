import React, { useContext } from 'react'
import { Box, Button, Text } from '@chakra-ui/react';
import PropertiesForm from './PropertiesForm';
import useForms from '../../hooks/useForms';
import { createProperty } from '../../firebase/Properties';
import useAsync from '../../hooks/useAsync';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/UI/Header';
import { Context } from '../../context/authContext';
import { AiOutlineArrowLeft } from 'react-icons/ai';


const INITIAL_DATA = {
    propertyName: '',
    propertyDescription: '',
    steps: {

    },

    // lat, lng
    coordinates: [],
    addressText: '',
    bathrooms: 0,
    bedrooms: 0,
    checkIn: null,
    checkOut: null,
    accessCode: '',
    ownerInfo: {},
    images: []
}

const AddEditProperties: React.FC = () => {

    const { inputsState, handleChange, cb } = useForms(INITIAL_DATA);
    const { currentUser } = useContext<any>(Context)
    const navigate = useNavigate();

    const handleAction = async () => {
        try {
            await createProperty({
                ...inputsState,
                teamId: currentUser.teams[0]
            })
            navigate('/property')

        } catch (err) {
            console.log(err)
        }
    }
    const { execute, status } = useAsync(handleAction, false);

    return (
        <Box h={'100vh'} bgColor={'#F5F5F5'} overflowY={'auto'}>
            <Box px={'40px'} pt={'20px'} fontFamily="poppins"    >
                <Box>
                    <Button pl={0} variant={'ghost'} onClick={() => navigate(-1)} >
                        <AiOutlineArrowLeft />
                        <Text ml={2}>Go Back</Text>
                    </Button>
                </Box>
                <Header />
                <PropertiesForm
                    handleChange={handleChange}
                    formData={inputsState}
                    handleAction={execute}
                    isLoading={status === 'pending' ? true : status === 'success' ? false : false}

                    cb={cb}
                />
            </Box>
        </Box>
    )
}


export default AddEditProperties;
