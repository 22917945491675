import { Box, Button, Checkbox, Flex, Image, Progress, Spinner, Text, Textarea, useToast } from '@chakra-ui/react';
import { doc, getDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Timer from '../../components/Cleaning/Timer';
import { updateCleaning } from '../../firebase/Cleanings';
import { db } from '../../firebase/init';
import useAsync from '../../hooks/useAsync';
import { storageRef } from './../../firebase/upload'
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';

import { v4 as uuidv4 } from 'uuid';
import { useAuthContext } from '../../context/authContext';
import Layout, { Profile, Tasks, TimeTrack } from '../../components/Cleaning/Upload';
import Header from '../../components/UI/Header';
import { BiArrowBack, BiTask } from 'react-icons/bi';
import { createNotification } from '../../firebase/notifications';

function isEven(n: any) {
    return n % 2 == 0;
}

const getTimeDiff = (start: number, end: number) => {
    return Math.floor((end - start) / 1000);
};
export const getTotalTime = (timeArr: any) => {
    let i = 0;
    let totalTime = 0;
    while (i < timeArr.length) {
        const start = timeArr[i];
        const end = timeArr[i + 1];

        if (!start || !end) {
            i = i + 2;

        } else {
            const diff = getTimeDiff(start, end);
            totalTime = totalTime + diff;
            i = i + 2;

        }

    }

    if (!isEven(timeArr.length)) {
        const diff = getTimeDiff(timeArr[timeArr.length - 1], new Date().getTime());
        totalTime = totalTime + diff;

    }

    return totalTime;
};

const CleaningDetails: React.FC = () => {
    const { currentUser } = useAuthContext()
    const [cleaning, setCleaning] = useState<any>(null);
    const [showSubmitState, setShowSubmitState] = useState(false);
    const [reportText, setReportText] = useState<string>('');
    const [seconds, setSeconds] = useState<number>(0);
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    const [validationState, setValidationState] = useState({
        isStartedValidation: false,
        isInComplete: false,
        imageRequiredValidation: {}
    });
    const toast = useToast()


    const navigate = useNavigate()
    const { isStartedValidation, isInComplete, imageRequiredValidation } = validationState;
    const { cleaningId } = useParams<any>();
    let shouldHaveControl = currentUser?.uid === cleaning?.assignedCleaner.uid;





    const handleStart = () => {
        setIsStarted(true)
        if (cleaning && !cleaning.timeArray) {
            const newStartedTime = new Date().getTime();
            // @ts-ignore
            updateCleaning(cleaningId, {
                timeArray: {
                    [currentUser.uid]: {
                        uid: currentUser.uid,
                        time: [newStartedTime]
                    }
                },
                status: 'started'
            })

            const { assignedProperty } = cleaning;
            const { ownerInfo } = assignedProperty

            createNotification({
                createdBy: currentUser.uid,
                createdByName: currentUser.displayName,
                notifyTo: {
                    uid: ownerInfo.uid,
                    displayName: ownerInfo.displayName,
                    avatar: ownerInfo.avatar

                },
                propertyInfo: {
                    id: assignedProperty.id,
                    propertyName: assignedProperty.propertyName
                }

                ,
                createdAt: serverTimestamp()

            })

        } else if (cleaning && cleaning.timeArray) {
            const newStartedTime = new Date().getTime();

            // @ts-ignore
            updateCleaning(cleaningId, {

                timeArray: {
                    ...cleaning.timeArray,
                    [currentUser.uid]: {
                        uid: currentUser.uid,
                        time: [...cleaning.timeArray[currentUser.uid].time, newStartedTime]
                    }
                },
                status: 'started'
            })
        }

    }

    const handlePause = () => {
        const pauseTime = new Date().getTime();

        /* @ts-ignore */
        updateCleaning(cleaningId, {
            timeArray: {
                ...cleaning.timeArray,
                [currentUser.uid]: {
                    uid: currentUser.uid,
                    time: [...cleaning.timeArray[currentUser.uid].time, pauseTime]
                }
            },
            status: 'started'
        })

        setIsStarted(false)
    }



    useEffect(() => {


        // If Cleaning Already Have started Tracking Time
        const timeArray = cleaning?.timeArray

        if (timeArray) {

            if (cleaning && !isEven(timeArray[Object.keys(timeArray)[0]].time.length)) {
                const timeArrayOfUser = timeArray[Object.keys(timeArray)[0]].time
                let workedTime = getTotalTime(timeArrayOfUser);
                setSeconds(workedTime)

                if (cleaning.status !== 'completed') {
                    setIsStarted(true)
                }



            } else if (cleaning && isEven(timeArray[Object.keys(timeArray)[0]].time.length)) {
                const timeArrayOfUser = timeArray[Object.keys(timeArray)[0]].time
                let workedTime = getTotalTime(timeArrayOfUser);
                setSeconds(workedTime)
            }
        }

    }, [cleaning])


    const validateBeforeChecked = async (stepId: string, taskId: any, e: any, isImgReq: any) => {

        if (!cleaning.status) return
        if (cleaning.status === 'completed') return

        if (!shouldHaveControl) return
        if (!isStarted && cleaning.status && cleaning.status !== 'started') {

            setValidationState({
                ...validationState,
                isStartedValidation: true
            })
            return
        }




        let newValue = { ...cleaning };
        const steps = newValue.assignedProperty.steps[stepId];
        const tasks = steps.tasks;
        const itemWillRemovedIdx = tasks.findIndex((el: any) => el.id === taskId);
        /* Image validation */
        if (isImgReq && !cleaning.taskImage.filter((el: any) => el.taskId === taskId).length) {
            setValidationState({
                ...validationState,
                imageRequiredValidation: {
                    [taskId]: true
                }
            })
            return
        }
        newValue.assignedProperty.steps[stepId].tasks[itemWillRemovedIdx] = {
            ...newValue.assignedProperty.steps[stepId].tasks[itemWillRemovedIdx],
            isCompleted: e
        }
        // @ts-ignore
        updateCleaning(cleaningId, newValue)
        setCleaning(newValue)
    }




    const completeCleaning = async () => {
        if (cleaning.status === 'completed') return


        if (!isStarted && cleaning.status !== 'started') {
            setValidationState({ ...validationState, isStartedValidation: true })
            return
        }
        let isInComplete = false;
        Object.values(cleaning.assignedProperty.steps).forEach((val: any) => {
            val.tasks.forEach((el: any) => {
                if (!el.isCompleted) {
                    isInComplete = true
                }
            })
        })

        if (isInComplete) {
            setValidationState({ ...validationState, isInComplete: true })
            return
        }

        let isImageValidationPassed: any = false;

        Object.values(imageRequiredValidation).forEach((el) => {

            if (isImageValidationPassed) return;
            isImageValidationPassed = el

        })


        if (isImageValidationPassed) {
            return
        }


        let It = new Date().getTime();

        let newTimeArr = cleaning.timeArray;
        if (isStarted) {
            newTimeArr[currentUser.uid].time = [
                ...newTimeArr[currentUser.uid].time,
                It

            ]

        }




        // @ts-ignore
        await updateCleaning(cleaningId, {
            ...cleaning,
            endTime: It,
            status: 'completed',
            timeArray: newTimeArr
        })
        const { assignedProperty } = cleaning;
        const { ownerInfo } = assignedProperty
        createNotification({
            createdBy: currentUser.uid,
            createdByName: currentUser.displayName,
            notifyTo: {
                uid: ownerInfo.uid,
                displayName: ownerInfo.displayName,
                avatar: ownerInfo.avatar

            },
            propertyInfo: {
                id: assignedProperty.id,
                propertyName: assignedProperty.propertyName
            },
            type: 'completed',


            createdAt: serverTimestamp()

        })

        toast({
            title: 'Cleaning completed.',
            description: "We've notified your owner about it.",
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }

    const { execute, status: updateCleaningStatus } = useAsync(completeCleaning, false);

    useEffect(() => {
        let interval: any = () => ''
        if (isStarted) {

            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);

        }


        if (cleaning?.status === 'completed') {
            clearInterval(interval)
        }


        return () => {
            clearInterval(interval)
        }
    }, [isStarted, cleaning]);



    useEffect(() => {
        /* @ts-ignore */
        onSnapshot(doc(db, "cleanings", cleaningId), (doc) => {
            let data: any = doc.data();
            setCleaning(data)

        });
    }, [])

    const handleReportSubmit = async () => {

        if (!reportText) return;

        let reportObject: any = {};
        const reportId = uuidv4();

        reportObject[reportId] = {
            createdAt: new Date().getTime(),
            reportText,
            id: reportId,
            flagged: 'Action Needed'
        }

        // @ts-ignore
        await updateCleaning(cleaningId, {
            reports: {
                ...cleaning.reports,
                ...reportObject
            }
        })


        setShowSubmitState(false)

    }
    const { execute: reportExecute, status: reportStatus, } = useAsync(handleReportSubmit, false)
    const errorStyle = {
        fontSize: '14px',
        color: '#E56E73',
        fontWeight: 500,
        mt: 1
    };


    return (
        <Box p={'40px'} pt={'25px'} height="100vh" overflowY={'scroll'}>

            {
                !cleaning ? (
                    <Flex alignItems={'center'} justifyContent={'center'} mt={'40px'}>
                        <Spinner />
                    </Flex>
                ) : (
                    <>
                        <Flex>
                            <Button pl={0} variant={'ghost'} onClick={() => navigate(-1)}>
                                <BiArrowBack />
                                <Text ml={1}>Go back</Text>
                            </Button>
                        </Flex>

                        <Layout cleaning={cleaning} shouldHaveControl={shouldHaveControl} handleReportClick={() => setShowSubmitState(true)} onCompleteCleaning={completeCleaning} showSubmitState={showSubmitState}>
                            <Header />
                            <Profile {...cleaning?.assignedProperty} {...cleaning?.assignedCleaner} status={cleaning?.status} />
                            <TimeTrack
                                seconds={seconds}
                                setSeconds={setSeconds}
                                handleStart={handleStart}
                                handlePause={handlePause}
                                value={cleaning}
                                isTicking={cleaning && cleaning.timeArray && !isEven(cleaning.timeArray[Object.keys(cleaning.timeArray)[0]].time.length)}
                                shouldHaveControl={shouldHaveControl}
                                cleaning={cleaning}
                            />
                            <Flex align={'center'} gap='.5rem' mt={4}>
                                <Box><BiTask fontSize='25px' color='#00A1A4' /></Box>
                                <Text fontSize={'1.5rem'} fontWeight='bold'>
                                    Tasks
                                </Text>
                            </Flex>

                            {
                                Object.keys(cleaning?.assignedProperty.steps).map((key: any) => (
                                    <Tasks
                                        {...cleaning?.assignedProperty.steps[key]}
                                        cleaning={cleaning}
                                        validateBeforeChecked={validateBeforeChecked}
                                        stepKey={key}
                                        validationState={validationState}
                                        taskImage={cleaning.taskImage}
                                        setValidationState={setValidationState}
                                        shouldHaveControl={shouldHaveControl}

                                    />
                                ))
                            }
                            {
                                isInComplete ? (
                                    <Text sx={errorStyle} mb={3}>Please Check Your all tasks In Each Steps </Text>
                                ) : isStartedValidation ? (
                                    <Text sx={errorStyle} mb={3}>Please Start  Your Timer before completing your Cleaning</Text>
                                ) : ''
                            }

                            <Box >
                                {
                                    showSubmitState && (
                                        <Box my={3}>
                                            <Textarea value={reportText} onChange={(e) => setReportText(e.target.value)} placeholder='Please Write something to describe'></Textarea>
                                            <Button mt={3} isLoading={reportStatus === 'pending'} onClick={reportExecute}>Submit Report</Button>
                                        </Box>
                                    )
                                }
                            </Box>

                        </Layout>
                    </>

                )
            }




        </Box>
    )
}


export default CleaningDetails;



const CleaningTextCss = {
    color: "red",
    border: '1px solid gray',
    fontSize: '18px',
    fontWeight: 600,
    p: '10px'
}