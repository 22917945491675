import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

import bathtub from './../../Assets/Images/bathtub.png';

const PropertiesItem: React.FC<any> = ({ propertyName, bedrooms, addressText, bathrooms, images }) => {
    return (
        <Box bg={'#FFFFFF'} p={4} borderRadius={'16px'} mb={5}>
            <Image objectFit={'cover'} borderRadius={'6px'} src={images[0]} height={'230px'} w={'100%'} />

            <Text fontWeight={500} fontSize={'18px'} my={'10px'}>{propertyName}</Text>
            <Box fontWeight={'medium'}>
                <Box>
                    <Flex alignItems={'flex-start'}>
                        <Image w={'23px'} h={'23px'} src={bathtub} />
                        <Text ml={3}>
                            {
                                addressText ? addressText : 'No address'
                            }
                        </Text>
                    </Flex>

                </Box>
                <Flex fontSize={'14px'} justifyContent={'space-between'} mt={'16px'} mb={'10px'} >
                    <Flex alignItems={'center'}>
                        <Image w={'23px'} h={'23px'} src={bathtub} />
                        <Text ml={2}>3000 sqft</Text>
                    </Flex>
                    <Flex alignItems={'center'}>
                        <Image w={'23px'} h={'23px'} src={bathtub} />
                        <Text ml={2}>{bedrooms} bedrooms</Text>
                    </Flex>
                    <Flex alignItems={'center'}>
                        <Image w={'23px'} h={'23px'} src={bathtub} />
                        <Text ml={2}>{bathrooms} bathrooms</Text>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    )
}


export default PropertiesItem;