import { Box, Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomInput, { InputLabel } from '../../components/Forms/Input/Input';
import { signupUser } from '../../firebase/auth';
import useAsync from '../../hooks/useAsync';
import useForms from '../../hooks/useForms';
import queryString from 'query-string';
import { getInvitation } from '../../firebase/team';

const INITIAL_DATA = {
    displayName: '',
    avatar: '',
    password: '',
    email: ''
}

const Signup: React.FC = () => {
    let searchParams = useLocation();
    const parsed = queryString.parse(searchParams.search);
    const [invitationState, setInvitationState] = useState<any>(null)
    const { inputsState, handleChange, cb } = useForms(INITIAL_DATA);
    const { displayName, email, password } = inputsState;
    const navigate = useNavigate()
    // Firebase Logic;
    const onSubmit = async () => {
        try {

            if (parsed.invitationid) {
                await signupUser(inputsState, invitationState, parsed.invitationid)

            } else {
                await signupUser(inputsState)

            }
            window.location.replace(`${window.location.origin}/property`);

        } catch (err) {
            console.log(err)
        }

    }

    const { execute, status, value, error } = useAsync(onSubmit, false);


    let buttonStyle = {
        color: 'white',
        bg: 'rgb(37, 206, 208)',
        h: '44px',

    }


    useEffect(() => {

        if (parsed.invitationid) {

            (async () => {
                const invitationData: any = await getInvitation(parsed.invitationid);
                setInvitationState(invitationData);

                cb({
                    email: invitationData.email
                })
            })()
        }

    }, []);


    return (
        <Box px={'30px'} fontFamily="Poppins" >
            <Text mt={6} fontWeight={500} fontSize={'24px'}>Create Account</Text>
            <Text color={'#959AA2'} mt={'10px'} fontSize={'15px'} textTransform={'capitalize'}>Connect with your friends today!</Text>
            <Box mt={'45px'}>

                <CustomInput placeholder='Enter your Name' name="displayName" handleChange={handleChange} value={displayName} >
                    <InputLabel label='Display Name' />
                </CustomInput>
                <CustomInput placeholder='Enter your email' name="email" handleChange={handleChange} value={email} disabled={parsed.invitationid ? true : false} >
                    <InputLabel label='email' />
                </CustomInput>
                <CustomInput placeholder='Enter your Password' name="password" handleChange={handleChange} value={password} >
                    <InputLabel label='Password' />
                </CustomInput>



                <Flex my={'23px'}>
                    <Checkbox sx={{ borderRadius: '100px' }} />
                    <Text ml={2} fontWeight={500}>I agree to the terms and conditions</Text>
                </Flex>
                <Button sx={buttonStyle} w={'100%'} type='submit' isLoading={status === 'pending'} onClick={execute}  >Sign up</Button>
                <Text>Or</Text>
                <Button onClick={() => navigate('/login')}>Login</Button>
            </Box>


        </Box>
    )
}

export default Signup;




